import React, { useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { useSelector } from 'react-redux';
import TabWrapper from './TabWrapper';

const Contacts = () => {
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];

  const itemsCate = sub_branches.find(s => s.name === 'Contacts');

  const categories = useMemo(() => {
    let result = [];
    if (itemsCate && itemsCate.sub_branches) {
      result = [...itemsCate.sub_branches];
    }
    result.reverse();
    return result;
  }, [itemsCate]);

  const title = 'Contacts';

  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <div className="test">
          {categories && categories.length > 0 && (
            <TabWrapper categories={categories} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Contacts;
