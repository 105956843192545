import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Card } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ImageUploadField from 'components/common/file-upload/ImageUploadField';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from 'services/constants';
import Loading from 'components/loading';
import Treeview from 'components/common/tree-view/TreeviewSelectCatgory';
import { toast } from 'react-toastify';
import { getErrorMesssage } from 'services/axiosHelpers';

const CategoryEditModal = ({
  show,
  isAdd,
  categoryId,
  categories,
  handleClose,
  success
}) => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const title = isAdd ? t('New Category') : t('Edit Category');
  const btnCaption = t('Save');
  const mn = 'bb_q4sg_content_categories';
  const [selectedCategories, setSeletectedCategories] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    refers_toISbb_q4sg_content_categoriesID: '',
    summaryISsmallplaintextbox: '',
    image01ISfile: null,
    image02ISfile: null,
    image03ISfile: null
  });

  const [summary, setSummary] = useState(RichTextEditor.createEmptyValue());

  const onChangeSummary = data => {
    const htmlData = data.toString('html');
    setSummary(data);
    const newFormData = {
      ...formData,
      summaryISsmallplaintextbox: htmlData
    };

    setFormData(newFormData);
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const uploadFile = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  const showToastMessage = (type, message) => {
    if (type === 'success') {
      toast.success(message, {
        theme: 'colored'
      });
    } else {
      toast.error(message, {
        theme: 'colored'
      });
    }
  };

  const save = async formData => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = formData;
      data = {
        name: formData.name,
        refers_toISbb_q4sg_content_categoriesID:
          formData.refers_toISbb_q4sg_content_categoriesID,
        summaryISsmallplaintextbox: formData.summaryISsmallplaintextbox
      };

      const ep = endpoint.getDataAddEndpoint(mn);
      const res = await axios.post(ep, data);

      if (res.data && res.data._id) {
        await update(formData, res.data._id);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const update = async (formData, categoryId) => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = formData;

      const ep = endpoint.getDataItemEditEndpoint(mn, categoryId);
      const res = await axios.patch(ep, data);

      if (res.data) {
        _isMounted.current && success();
        _isMounted.current && handleClose();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (isAdd) {
      await save(formData);
    } else {
      await update(formData, categoryId);
    }
  };

  const getCategory = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getDataItemViewEndpoint(mn, _id)}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        setupForm(infoData);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const setupForm = infoData => {
    const initFormData = {
      name: infoData.name ?? '',
      refers_toISbb_q4sg_content_categoriesID:
        infoData.refers_toISbb_q4sg_content_categoriesID ?? '',
      summaryISsmallplaintextbox: infoData.summaryISsmallplaintextbox ?? '',
      image01ISfile: infoData.image01ISfile ?? null,
      image02ISfile: infoData.image02ISfile ?? null,
      image03ISfile: infoData.image03ISfile ?? null
    };
    if (infoData.summaryISsmallplaintextbox) {
      setSummary(
        RichTextEditor.createValueFromString(
          infoData.summaryISsmallplaintextbox,
          'html'
        )
      );
    }
    setFormData(initFormData);

    if (infoData.refers_toISbb_q4sg_content_categoriesID) {
      setSeletectedCategories([
        infoData.refers_toISbb_q4sg_content_categoriesID
      ]);
    }
  };

  const setCategory = items => {
    if (items && items.length > 0) {
      setSeletectedCategories(items);
      setFormData({
        ...formData,
        refers_toISbb_q4sg_content_categoriesID: items[0]
      });
    } else {
      setSeletectedCategories([]);
      setFormData({
        ...formData,
        refers_toISbb_q4sg_content_categoriesID: ''
      });
    }
  };

  const removeFile = async file => {
    if (isAdd || !categoryId) {
      return;
    }
    try {
      const ep = `${endpoint.getDataItemEditEndpoint(
        mn,
        categoryId
      )}/files/${file}`;
      await axios.delete(ep);
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    }
  };

  const setup = () => {
    if (isAdd) {
      _isMounted.current && setupForm({});
    } else {
      _isMounted.current && getCategory(categoryId);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size="lg"
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          {/* <Modal.Title as="h5" className="text-white">
            {title}
          </Modal.Title> */}
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Card.Body className="">
              <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
            </Card.Body>
          ) : (
            <fieldset className={'form-legend-wrapper pb-6 px-4'}>
              <legend className="item-title fs-1 fw-semi-bold">
                {t(title)}
              </legend>
              <Form onSubmit={handleSubmit} className="pt-4">
                <Row>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper mb-4'}>
                      <legend className="item-title fs-1 fw-semi-bold">
                        {t('Name')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper mb-4 px-4'}>
                      <legend className="item-title fs-1 fw-semi-bold">
                        {t('Refer to')}
                      </legend>
                      <Treeview
                        data={categories}
                        selection
                        selectedItems={selectedCategories}
                        expanded={selectedCategories}
                        setSelectedItems={setCategory}
                      />
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper mb-4'}>
                      <legend className="item-title fs-1 fw-semi-bold">
                        {t('Summary')}
                      </legend>
                      <Form.Group className="mb-0">
                        <RichTextEditor
                          toolbarConfig={toolbarConfig}
                          editorClassName="editor-wrapper"
                          value={summary}
                          onChange={onChangeSummary}
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <Row className="mb-4">
                  {[1, 2, 3].map((num, index) => (
                    <Col lg={4} key={index}>
                      <fieldset className={'form-legend-item-wrapper mb-4 p-2'}>
                        <legend className="item-title fs-1 fw-semi-bold">
                          {`${t('Image')} ${num}`}
                        </legend>
                        <ImageUploadField
                          inputImage={formData[`image0${num}ISfile`]}
                          uploadFile={f => uploadFile(f, `image0${num}ISfile`)}
                          removeFile={() => removeFile(`image0${num}ISfile`)}
                        />
                      </fieldset>
                    </Col>
                  ))}
                </Row>

                <div className="text-end">
                  <Button variant="primary" type="submit" disabled={saving}>
                    {saving ? `${t('Saving')}...` : btnCaption}
                  </Button>
                </div>
              </Form>
            </fieldset>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

CategoryEditModal.propTypes = {
  show: PropTypes.bool,
  isAdd: PropTypes.bool,
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default CategoryEditModal;
