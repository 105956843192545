import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AlertModal = ({ isShow, title, message, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      className="mt-4"
      centered
      size="lg"
    >
      <Modal.Body className="p-4">
        <fieldset className={'form-legend-item-wrapper mb-4 p-6'}>
          <legend className="item-title fs-1 fw-semi-bold">{title}</legend>
          <div className="text-center">
            <div className="description text-center">{message}</div>
            <Button
              type="submit"
              color="primary"
              className="mt-6 w-100"
              onClick={handleClose}
            >
              {t('Close')}
            </Button>
          </div>
        </fieldset>
      </Modal.Body>
    </Modal>
  );
};

AlertModal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default AlertModal;
