import { useState, useEffect } from 'react';

const useTabletScreenSize = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsTablet(1060 <= window.innerWidth && window.innerWidth <= 1286);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isTablet;
};
export default useTabletScreenSize;