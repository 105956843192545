import React, { useMemo } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const PortfolioFilterSlider = ({
  portfolioFilter,
  portfolioByCountry,
  isShowPortfolioItems,
  setIsShowPortfolioItems,
  onGoItem,
  portfoliosCountryFiltered,
  setPortfoliosCountryFiltered
}) => {
  const { t } = useTranslation();

  const sliderSettings = useMemo(() => {
    if (!portfolioFilter || !portfolioByCountry) {
      return;
    }
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: portfolioFilter.length < 4 ? portfolioFilter.length : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }
      ]
    };
    return settings;
  }, [portfolioByCountry, portfolioFilter]);

  return (
    <>
      {!isShowPortfolioItems && (
        <Slider {...sliderSettings} className="slick-slider-arrow-inner">
          {portfolioFilter &&
            portfolioFilter?.map((filter, i) => (
              <div
                className="slide-wrapper hover-overlay-effect"
                onClick={() => {
                  const filterWord = filter.summaryISsmallplaintextbox;
                  const filteredItems =
                    filterWord === 'all'
                      ? portfolioByCountry
                      : portfolioByCountry?.filter(item =>
                          item.name.toLowerCase().includes(filterWord)
                        );
                  setPortfoliosCountryFiltered(filteredItems);
                  setIsShowPortfolioItems(true);
                }}
                key={i}
              >
                <div className="image-wrapper m-2">
                  <Image
                    fluid
                    src={filter['image01ISfile'] ?? placeholderImage}
                    className="portfolio-image"
                  />
                </div>
                <div className="">
                  <div className="text-center mt-2 fw-semi-bold fs-1">
                    {t(filter['name'])}
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      )}
      {isShowPortfolioItems && (
        <div className="d-flex flex-column">
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setIsShowPortfolioItems(false);
              setPortfoliosCountryFiltered([]);
            }}
          >
            <MdArrowBackIos />
            <span>{t('Go Back')}</span>
          </div>

          {portfoliosCountryFiltered?.length ? (
            <Slider {...sliderSettings} className="slick-slider-arrow-inner">
              {portfoliosCountryFiltered.map((slide, i) => (
                <div
                  className="slide-wrapper hover-overlay-effect"
                  onClick={() => onGoItem(slide['_id'])}
                  key={i}
                >
                  <div className="image-wrapper m-2">
                    <Image
                      fluid
                      src={slide['image01ISfile'] ?? placeholderImage}
                      className="portfolio-image"
                    />
                  </div>
                  <div className="">
                    <div className="text-center mt-2 fw-semi-bold fs-1">
                      {t(slide['name'])}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="d-flex justify-content-center w-100">
              <span className="fw-semi-bold">
                {t('No elements in this Portfolio')}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PortfolioFilterSlider;
