import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SearchableSelect = ({
  loading,
  fieldName,
  label,
  placeholder,
  defaultValue,
  inValue = null,
  optionList,
  onChangeSelect
}) => {
  const _isMounted = useRef(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const value = useMemo(() => {
    if (!selectedValue) {
      return inValue ?? defaultValue;
    }
    return selectedValue;
  }, [selectedValue, inValue]);
  const onSelected = e => {
    setSelectedValue(e.target.value);
    onChangeSelect(e);
  };
  useEffect(() => {
    _isMounted.current = true;
    //setValue(initVal);
    return () => {
      _isMounted.current = false;
    };
  }, []);
  return (
    <>
      <Form.Group as={Col} lg={12} controlId={fieldName}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          value={value}
          name={fieldName}
          onChange={onSelected}
          required
          className="form-select"
        >
          {loading ? (
            <option value={defaultValue}>Getting options...</option>
          ) : (
            <>
              <option value={defaultValue}>{placeholder}</option>
              {optionList.map((option, index) => (
                <option value={option._id} key={index}>
                  {option.name}
                </option>
              ))}
            </>
          )}
        </Form.Control>
      </Form.Group>
    </>
  );
};

SearchableSelect.propTypes = {
  loading: PropTypes.bool,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  inValue: PropTypes.any,
  optionList: PropTypes.array,
  onChangeSelect: PropTypes.func
};

export default SearchableSelect;
