import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const PasswordResetForm = () => {
  const { t } = useTranslation();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    toast.success(`Logged in as ${formData.email}`, {
      theme: 'colored'
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <fieldset className={'form-legend-wrapper pb-6'}>
        <legend className="item-title fs-1 fw-semi-bold">
          {t('Password Reset')}
        </legend>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Form onSubmit={handleSubmit}>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Email')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                  />
                </Form.Group>
              </fieldset>

              <Form.Group>
                <Button
                  type="submit"
                  color="primary"
                  className="mt-3 w-100"
                  disabled={!formData.email}
                >
                  {t('Submit')}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </fieldset>
      <div className="text-center mt-4">
        <div className="text-center mb-3">
          <Link to="/login">{`< ${t('Back to Login')}`}</Link>
        </div>
        <div className="text-center mb-3">
          <Link to="/signup">{t('Request Access')}</Link>
        </div>
      </div>
    </>
  );
};

PasswordResetForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

PasswordResetForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default PasswordResetForm;
