import React, { useEffect, useMemo, useState } from 'react';
import { Image, Tab, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import placeholderImage from 'assets/img/placeholder.png';
import useMobileScreenSize from 'hooks/useMobileScreenSize';
import { MdArrowBackIos } from 'react-icons/md';
import PortfolioFilter from './PortfolioFilter';
import PortfolioFilterSlider from './PortfolioFilterSlider';
import { replaceSpacesWithDashes } from 'helpers/utils';
import {
  updateElementContentId,
  updateElementContentName
} from 'redux/subCategoriesSlice';

const Portfolio = ({ title, data, kind, className, portfolioFilter }) => {
  // const portfolioFilter = [
  //   {
  //     image01ISfile:
  //       'https://skytoolkit-dev.blueboxonline.co.uk/portal/lamp01/custom_modules/img/currys_logo.png',
  //     name: "Curry's",
  //     summaryISsmallplaintextbox: 'all',
  //     urlISplaintextbox: null,
  //     _dateadded: '2024-06-18 09:07:25',
  //     _id: 'n/a'
  //   },
  //   {
  //     image01ISfile:
  //       'https://skytoolkit-dev.blueboxonline.co.uk/portal/lamp01/custom_modules/img/currys_logo.png',
  //     name: 'Reduced',
  //     summaryISsmallplaintextbox: 'reduced',
  //     urlISplaintextbox: null,
  //     _dateadded: '2024-06-19 09:07:25',
  //     _id: 'n/a'
  //   },
  //   {
  //     image01ISfile:
  //       'https://skytoolkit-dev.blueboxonline.co.uk/portal/lamp01/custom_modules/img/currys_logo.png',
  //     name: 'Standard',
  //     summaryISsmallplaintextbox: 'standard',
  //     urlISplaintextbox: null,
  //     _dateadded: '2024-06-20 09:07:25',
  //     _id: 'n/a'
  //   },
  //   {
  //     image01ISfile:
  //       'https://skytoolkit-dev.blueboxonline.co.uk/portal/lamp01/custom_modules/img/currys_logo.png',
  //     name: 'Reduced',
  //     summaryISsmallplaintextbox: 'reduced',
  //     urlISplaintextbox: null,
  //     _dateadded: '2024-06-19 09:07:25',
  //     _id: 'n/a'
  //   },
  //   {
  //     image01ISfile:
  //       'https://skytoolkit-dev.blueboxonline.co.uk/portal/lamp01/custom_modules/img/currys_logo.png',
  //     name: 'Standard',
  //     summaryISsmallplaintextbox: 'standard',
  //     urlISplaintextbox: null,
  //     _dateadded: '2024-06-20 09:07:25',
  //     _id: 'n/a'
  //   }
  // ];
  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMobileScreenSize();

  const [country, setCountry] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [isShowPortfolioItems, setIsShowPortfolioItems] = useState(false);
  const [portfoliosCountryFiltered, setPortfoliosCountryFiltered] = useState(
    []
  );
  const [portfoliosFilteredEnglish, setPortfoliosFilteredEnglish] = useState(
    []
  );

  useEffect(() => {
    if (countries.length > 0) {
      setCountry(countries[0].name);
    }
  }, [data]);

  useEffect(() => setShowMore(isMobile ? true : false), [isMobile]);

  useEffect(() => {
    if (languageCode !== 'GB' && portfoliosCountryFiltered.length > 0) {
      const translatedFilteredPortfolio = portfoliosCountryFiltered.map(
        item => {
          return {
            ...item,
            name: translations?.[item._id]?.[languageCode]?.name
          };
        }
      );
      setPortfoliosCountryFiltered(translatedFilteredPortfolio);
    } else if (languageCode === 'GB' && portfoliosCountryFiltered.length > 0) {
      setPortfoliosCountryFiltered(portfoliosFilteredEnglish);
    }
  }, [languageCode]);

  const countries = useMemo(() => {
    let result = [];
    data.forEach(element => {
      const country = {
        key: element.name,
        name: element.name
      };
      result = [...result, country];
    });
    return result;
  }, [data]);

  const portfoliosEnglish = useMemo(() => {
    let result = {};
    data.forEach(item => {
      result = {
        ...result,
        [item.name]: item.content_list
      };
    });
    return result;
  }, [data]);

  const portfolios = useMemo(() => {
    let result = {};
    data.forEach(item => {
      result = {
        ...result,
        [item.name]: item.content_list.map(el => {
          return {
            ...el,
            name:
              languageCode === 'GB'
                ? el.name
                : translations?.[el._id]?.[languageCode]?.name
          };
        })
      };
    });
    return result;
  }, [data, languageCode, translations]);

  const counts = useMemo(() => {
    if (!country || !portfolios || !portfolios[country]) {
      return 0;
    }
    const result = portfolios[country].length;
    return result;
  }, [portfolios, country]);

  const toggleShowMore = () => setShowMore(!showMore);

  const sliderSettings = useMemo(() => {
    if (!country || !portfolios[country]) {
      return;
    }
    const settings = {
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: counts < 4 ? counts : 4,
      slidesToScroll: counts < 4 ? counts : 4,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        }
      ]
    };
    return settings;
  }, [country]);

  const onGoItem = _id => {
    const storeName = portfoliosEnglish[country].find(p => p._id === _id).name;
    dispatch(updateElementContentName(storeName));
    dispatch(updateElementContentId(_id));

    const url = replaceSpacesWithDashes(
      `/Dashboard/${kind}/Portfolio/${country}/${storeName}`
    );
    navigate(url);
  };

  return (
    <fieldset
      className={classNames(
        className,
        'form-legend-wrapper p-4 cursor-pointer border portfolio'
      )}
    >
      <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
        {t(title)}
      </legend>

      {country && (
        <Tab.Container
          activeKey={country}
          onSelect={k => setCountry(k)}
          id="countries-tab"
          transition={false}
        >
          <Nav variant="tabs" className="flex-row">
            {countries.map((c, index) => (
              <div
                key={index}
                onClick={() => {
                  setIsShowPortfolioItems(false);
                  setPortfoliosCountryFiltered([]);
                }}
              >
                <Nav.Item>
                  <Nav.Link eventKey={c.key} className="fw-semi-bold fs-1">
                    {t(c.name)}
                  </Nav.Link>
                </Nav.Item>
              </div>
            ))}
          </Nav>

          {showMore ? (
            <Flex
              justifyContent="start"
              alignItems="center"
              className="ms-3"
              wrap="wrap"
            >
              {!isShowPortfolioItems && portfolioFilter ? (
                portfolioFilter.map((slide, i) => (
                  <div
                    className="slide-wrapper hover-overlay-effect"
                    onClick={() => {
                      const filterWord = slide.summaryISsmallplaintextbox;
                      const filteredItems =
                        filterWord === 'all'
                          ? portfolios[country]
                          : portfolios[country]?.filter(item =>
                              item.name.toLowerCase().includes(filterWord)
                            );
                      setPortfoliosCountryFiltered(filteredItems);
                      setIsShowPortfolioItems(true);
                    }}
                    key={i}
                  >
                    <div className="image-wrapper m-2">
                      <Image
                        fluid
                        src={slide['image01ISfile'] ?? placeholderImage}
                        className="portfolio-image"
                      />
                    </div>
                    <div className="">
                      <div className="text-center mt-2 fw-semi-bold fs-1">
                        {t(slide['name'])}
                      </div>
                    </div>
                  </div>
                ))
              ) : portfoliosCountryFiltered.length > 0 ? (
                <div className="d-flex flex-column">
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      setIsShowPortfolioItems(false);
                      setPortfoliosCountryFiltered([]);
                    }}
                  >
                    <MdArrowBackIos />
                    <span>{t('Go Back')}</span>
                  </div>
                  <div className="d-flex flex-row flex-wrap w-100">
                    {portfoliosCountryFiltered.map((slide, i) => (
                      <div
                        className="slide-wrapper hover-overlay-effect"
                        onClick={() => onGoItem(slide['_id'])}
                        key={i}
                      >
                        <div className="image-wrapper m-2">
                          <Image
                            fluid
                            src={slide['image01ISfile'] ?? placeholderImage}
                            className="portfolio-image"
                          />
                        </div>
                        <div className="">
                          <div className="text-center mt-2 fw-semi-bold fs-1">
                            {t(slide['name'])}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                portfolios[country].map((slide, i) => (
                  <div
                    className="slide-wrapper hover-overlay-effect"
                    onClick={() => onGoItem(slide['_id'])}
                    key={i}
                  >
                    <div className="image-wrapper m-2">
                      <Image
                        fluid
                        src={slide['image01ISfile'] ?? placeholderImage}
                        className="portfolio-image"
                      />
                    </div>
                    <div className="">
                      <div className="text-center mt-2 fw-semi-bold fs-1">
                        {t(slide['name'])}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </Flex>
          ) : (
            <>
              {portfolioFilter?.length > 4 || counts > 4 ? (
                <>
                  {
                    // !isShowPortfolioItems &&
                    portfolioFilter ? (
                      <PortfolioFilterSlider
                        portfolioFilter={portfolioFilter}
                        portfolioByCountry={
                          portfoliosCountryFiltered.length
                            ? portfoliosCountryFiltered
                            : portfolios[country]
                        }
                        isShowPortfolioItems={isShowPortfolioItems}
                        setIsShowPortfolioItems={setIsShowPortfolioItems}
                        onGoItem={onGoItem}
                        portfoliosCountryFiltered={portfoliosCountryFiltered}
                        setPortfoliosCountryFiltered={
                          setPortfoliosCountryFiltered
                        }
                        setPortfoliosFilteredEnglish={
                          setPortfoliosFilteredEnglish
                        }
                      />
                    ) : (
                      <Slider
                        {...sliderSettings}
                        className="slick-slider-arrow-inner"
                      >
                        {portfolios[country].map((slide, i) => (
                          <div
                            className="slide-wrapper hover-overlay-effect"
                            onClick={() => onGoItem(slide['_id'])}
                            key={i}
                          >
                            <div className="image-wrapper">
                              <Image
                                fluid
                                src={slide['image01ISfile']}
                                className="portfolio-image"
                              />
                            </div>
                            <div className="slide-title">
                              <div className="text-center mt-2 fw-semi-bold fs-1">
                                {t(slide['name'])}
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )
                  }
                </>
              ) : (
                <>
                  {portfolioFilter ? (
                    <PortfolioFilter
                      portfolioFilter={portfolioFilter}
                      portfolioByCountry={portfolios[country]}
                      isShowPortfolioItems={isShowPortfolioItems}
                      setIsShowPortfolioItems={setIsShowPortfolioItems}
                      onGoItem={onGoItem}
                      portfoliosCountryFiltered={portfoliosCountryFiltered}
                      setPortfoliosCountryFiltered={
                        setPortfoliosCountryFiltered
                      }
                      setPortfoliosFilteredEnglish={
                        setPortfoliosFilteredEnglish
                      }
                    />
                  ) : (
                    <Flex
                      justifyContent="start"
                      alignItems="center"
                      className="ms-3"
                      wrap="wrap"
                    >
                      {portfolios[country].map((slide, i) => (
                        <div
                          className="slide-wrapper hover-overlay-effect"
                          onClick={() => onGoItem(slide['_id'])}
                          key={i}
                        >
                          <div className="image-wrapper m-2">
                            <Image
                              fluid
                              src={slide['image01ISfile'] ?? placeholderImage}
                              className="portfolio-image"
                            />
                          </div>
                          <div className="">
                            <div className="text-center mt-2 fw-semi-bold fs-1">
                              {t(slide['name'])}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
        </Tab.Container>
      )}
      {(portfoliosCountryFiltered?.length > 4 ||
        (!isShowPortfolioItems && portfolioFilter?.length > 4) ||
        counts > 4) && (
        <div
          onClick={toggleShowMore}
          className="fs-1 fw-semi-bold show-mode-btn px-4"
        >
          {showMore ? `${t('Show Less')}...` : `${t('Show More')}...`}
        </div>
      )}
    </fieldset>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  kind: PropTypes.string,
  data: PropTypes.array
};

export default Portfolio;
