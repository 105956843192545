import React, { Fragment, useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableWrapper from './TableWrapper';

const SubTabWrapper = ({ categories, topSelectedKey }) => {
//   console.log( categories, 'categories-SubTabWrapper')
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState(categories.find(item=>item.name==='UK')._id);

  useEffect(
    ()=>{
     setSelectedKey(categories.find(item=>item.name==='UK')._id)
    },[topSelectedKey, categories]
  )
  return (
    <Tab.Container
      activeKey={selectedKey}
      onSelect={k => setSelectedKey(k)}
      id="countries-tab"
      transition={true}
    >
      <Flex justifyContent="between" alignItems="center" className="">
        <Nav variant="tabs" className="flex-row">
          {categories.map((c, index) => (
            <Fragment key={index}>
              <Nav.Item>
                <Nav.Link eventKey={c._id} className="fw-semi-bold fs-1">
                  {t(c.name)}
                </Nav.Link>
              </Nav.Item>
            </Fragment>
          ))}
        </Nav>
      </Flex>
      <Tab.Content>
        {categories.map((c, index) => (
          <Fragment key={index}>
            <Tab.Pane eventKey={c._id} title={c.name} className="p-3 pt-0">
                <TableWrapper id={c._id} />
            </Tab.Pane>
          </Fragment>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

SubTabWrapper.propTypes = {
  categories: PropTypes.array.isRequired
};

export default SubTabWrapper;
