import { configureStore } from '@reduxjs/toolkit';
import appSettingReducer from './appsettingSlice';
import subCategoriesSlice from './subCategoriesSlice';
import authReducer, { authMiddleware } from './authSlice';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    appsetting: appSettingReducer,
    subCategories: subCategoriesSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware)
});
