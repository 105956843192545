import React, { useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionTable from './AccordionTable';
import AccordionItem from './AccordionItem';
import { truncateFolderName } from './helpers';
// import useMobileScreenSize from 'hooks/useMobileScreenSize';
import useTabletScreenSize from 'hooks/useTabletScreenSize';

const AccordionItemNested = ({ data }) => {
  //   console.log(data, 'Accordion-Nested-Item');
   // const isMobile = useMobileScreenSize();
   const isTablet = useTabletScreenSize();

  const accordionData = useMemo(() => {
    const itenData = { ...data };
    delete itenData.data;
    delete itenData.name;
    return Object.values(itenData);
  }, [data]);
  // console.log(accordionData, 'accordionData-Item Nested');
  return (
    <Accordion className="">
      {accordionData?.length > 0 &&
        accordionData?.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              {item?.name?.length > 100 || isTablet
                ? truncateFolderName(item.name)
                : item.name}
            </Accordion.Header>
            <Accordion.Body>
              {item?.data?.length > 0 && <AccordionTable item={item.data} />}
              {item && Object.values(item)?.length && (
                <div className="mt-3">
                  <AccordionItem data={item} />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};

export default AccordionItemNested;
