import React from 'react';
import PropTypes from 'prop-types';

const FalconImageViewButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      aria-label="Open Fullscreen"
      onClick={onClick}
    >
      <svg
        className="image-gallery-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
      </svg>
    </button>
  );
};

FalconImageViewButton.propTypes = {
  onClick: PropTypes.func
};

export default FalconImageViewButton;
