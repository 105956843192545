import React from 'react';
import { Image, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
// import Flex from 'components/common/Flex';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faComment,
//   faCommentDots,
//   faGenderless,
//   faHeart
// } from '@fortawesome/free-solid-svg-icons';

const NewsItemComponent = ({
  type,
  title,
  date,
  time,
  //team,
  image,
  content
  //favorite,
  //reply
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onGoto = () => {
    navigate('');
  };
  return (
    <fieldset
      className={
        'form-legend-wrapper p-4 my-4 text-center border news-item-wrapper'
      }
      onClick={onGoto}
      id={title}
    >
      <legend className="item-title fs-1 fw-semi-bold">
        <div className="news-item-title">
          <span className="me-2 fw-light">{`(${date})`}</span>
          <span className="me-2 fs--1 fw-light">{`${time}`}</span>
        </div>
      </legend>
      <div className="news-item-content">
        {type === 'main' ? (
          <>
            <Row>
              <Col>
                <Image rounded fluid src={image} alt="" />
              </Col>
              <Col>
                <div className="fw-semi-bold text-start">{t(title)}</div>
                <p
                  className="text-start"
                  dangerouslySetInnerHTML={createMarkup(content)}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <div className="fw-semi-bold text-start">{t(title)}</div>
            <p
              className="text-start"
              dangerouslySetInnerHTML={createMarkup(content)}
            />
          </>
        )}
      </div>
    </fieldset>
  );
};

NewsItemComponent.propTypes = {
  kind: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  team: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  favorite: PropTypes.bool,
  reply: PropTypes.bool
};

export default NewsItemComponent;
