import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import RelatedItemsAccordion from './RelatedItemsAccordion';

const RelatedItems = ({ relatedItems, className = '', isStore = false }) => {
  // console.log(relatedItems, 'relateditems');
  const { t } = useTranslation();
  const title = 'Related Items';

  const parseString = str => {
    return str.split(' > ');
  };

  const createNestedStructure = data => {
    let nestedStructure = [];
    let currentObject = null;

    data.forEach(item => {
      let arr = parseString(item.name);
      let url = item.url; // Get the url from the item object

      for (let i = 0; i < arr.length; i++) {
        let node = { name: arr[i] };

        if (i === 0) {
          // Check if the first node already exists
          let existingNode = nestedStructure.find(n => n.name === node.name);

          if (existingNode) {
            // If the first node already exists, use it as the current object
            currentObject = existingNode;
          } else {
            // If the first node does not exist, create it and add it to the nested structure
            currentObject = node;
            nestedStructure.push(currentObject);
          }
        } else {
          // Check if the current object has a child array
          if (!currentObject.children) {
            currentObject.children = [];
          }

          // Check if the child node already exists
          let existingNode = currentObject.children.find(
            n => n.name === node.name
          );

          if (existingNode) {
            // If the child node already exists, use it as the current object
            currentObject = existingNode;
          } else {
            // If the child node does not exist, create it and add it to the child array
            currentObject.children.push(node);
            currentObject =
              currentObject.children[currentObject.children.length - 1];
          }
        }
        // If the current object is the last node and does not have children, add the url to it
        if (i === arr.length - 1 && !currentObject.children) {
          currentObject.url = url;
        }
      }
    });

    return nestedStructure;
  };

  const relatedItemsNested = useMemo(
    () => createNestedStructure(relatedItems),
    [relatedItems]
  );
  // console.log(relatedItemsNested, 'nestedStructure');
  // ------------------------------------------------------------------------------------------------
  // const relatedItemsTableData = useMemo(() => {
  //   if (!relatedItems || relatedItems.length < 1) {
  //     return [];
  //   }
  //   let results = relatedItems.map(c => ({
  //     _id: c._id,
  //     name: c.name,
  //     url: c.url
  //   }));
  //   return results;
  // }, [relatedItems]);

  return (
    <div className={classNames(className, '')}>
      {relatedItems.length > 0 &&
        (isStore ? (
          <RelatedItemsAccordion accordionData={relatedItemsNested} />
        ) : (
          <fieldset className="form-legend-wrapper p-4 cursor-pointer border">
            <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
              {t(title)}
            </legend>
            <RelatedItemsAccordion accordionData={relatedItemsNested} />
          </fieldset>
        ))}
    </div>
  );
};

RelatedItems.propTypes = {
  className: PropTypes.string,
  relatedItems: PropTypes.array
};

export default RelatedItems;
