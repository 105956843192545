import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
// import RelatedItemsNestedAccordion from './RelatedItemsNestedAccordion';
import RelatedItemsTable from 'components/common/RelatedItemsTable';

const RelatedItemsAccordion = ({ accordionData }) => {
  return (
    <Accordion className="">
      {accordionData?.length > 0 &&
        accordionData?.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{item.name}</Accordion.Header>
            <Accordion.Body>
              {item?.children[0]?.url ? (
                <RelatedItemsTable relatedItemsTableData={item.children} />
              ) : (
                <RelatedItemsAccordion accordionData={item?.children} />
                // <RelatedItemsNestedAccordion accordionData={item?.children} />
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
};
export default RelatedItemsAccordion;
