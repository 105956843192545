import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import {replaceSpacesWithDashes} from 'helpers/utils';

const NavItemComponent = ({ title, icon, url, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGoto = () => {
    if (url) {
      navigate(replaceSpacesWithDashes(url));
    }
  };
  return (
    <fieldset
      className={classNames(
        className,
        'nav-item-comp p-4 text-center cursor-pointer border'
      )}
      onClick={onGoto}
    >
      <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
        {t(title)}
      </legend>
      <Flex justifyContent="center" alignItems="center" className="h-100">
        <div className="item-icon hover-overlay-effect">
          <Image rounded fluid src={icon} alt="" />
        </div>
      </Flex>
    </fieldset>
  );
};

NavItemComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string
};

export default NavItemComponent;
