import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import Footer from 'components/footer/Footer';
import Hero from 'components/header/Hero';
import QuickLinks from 'components/quick-links/QuickLinks';
import NewsList from 'components/news/NewsList';
import { Row, Col } from 'react-bootstrap';

const FrontLayout = () => {
  const { hash, pathname } = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={''}>
      <div className={classNames('content')}>
        <NavbarTop />
        <Hero />
        {/*------ Main Routes ------*/}
        <Row className="pt-3">
          <Col sm={12} lg={2}>
            <QuickLinks />
          </Col>
          <Col sm={12} lg={8}>
            <Outlet />
          </Col>
          <Col sm={12} lg={2}>
            <NewsList />
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
};

export default FrontLayout;
