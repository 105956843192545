import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import FalconImageModal from 'components/common/FalconImageModal';
import FalconImageViewButton from 'components/common/FalconImageViewButton';

const StoreSliderComponent = ({ data }) => {
  // console.log(data, 'data-storeSliderComponent')
  const { t } = useTranslation();

  const [isShowModal, setIsShowModal] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(null);
  const refImg = useRef(null);

  const title = data.title || '';

  const imageItems = useMemo(() => {
  const slides = data.slides || [];

    return slides.map(item => {
      const newItem = {
        ...item,
        original: item['image'],
        thumbnail: item['image']
      };
      return newItem;
    });
  }, [data.slides]);
  

  const handleClose = () => {
    setIsShowModal(false);
  };

  const onViewImageModal = () => {
    const index = refImg.current.getCurrentIndex();
    setCurrentSrc(imageItems[index].original);
    setIsShowModal(true);
  };

  const renderCustomControls = () => {
    return <FalconImageViewButton onClick={onViewImageModal} />;
  };
// console.log(imageItems, 'imageitems-StoreSLIDER')
  return (
    <>
      <fieldset
        className={
          'form-legend-wrapper p-4 cursor-pointer border portfolio-page'
        }
      >
        <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
          {t(title)}
        </legend>
        <ImageGallery
          ref={refImg}
          items={imageItems}
          showThumbnails={false}
          showFullscreenButton={false}
          renderCustomControls={renderCustomControls}
        />
      </fieldset>
      <FalconImageModal
        show={isShowModal}
        src={currentSrc}
        size="xl"
        handleClose={handleClose}
      />
    </>
  );
};

StoreSliderComponent.propTypes = {
  data: PropTypes.object
};

export default StoreSliderComponent;
