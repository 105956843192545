import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SignupForm from 'components/authentication/SignupForm';
import PageTitle from 'components/common/PageTitle';

const Signup = () => {
  const title = 'Request Access';
  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <SignupForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Signup;
