import { useState, useEffect } from 'react';

const useSmallTabletScreenSize = () => {
  const [isSmallTablet, setIsSmallTablet] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallTablet(window.innerWidth <= 1060);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isSmallTablet;
};
export default useSmallTabletScreenSize;