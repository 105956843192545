import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ResetPasswordForm from 'components/authentication/ResetPasswordForm';
import PageTitle from 'components/common/PageTitle';

const ResetPassword = () => {
  const title = 'Welcome';
  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <ResetPasswordForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPassword;
