import React, { useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Col, Row } from 'react-bootstrap';
import NavItemComponent from 'components/common/NavItemComponent';
import Portfolio from 'components/common/Portfolio';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import placeholderImage from 'assets/img/placeholder.png';
import {replaceDashesWithSpaces} from 'helpers/utils';

const Shops = () => {
  const { category:cat } = useParams();
  const category = cat && replaceDashesWithSpaces(cat);

  const title = category;
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
    const categoryId = sub_branches.find(c => c.name === category)._id;
    const portfolioFilter = categoryId === '9423' ? sub_branches.find(c => c.name === category)?.portfolio_filter : null;
  const inShops =
    sub_branches.find(c => c.name === category).sub_branches || [];
  const shops = inShops.filter(i => i.name !== 'Portfolio');
  const index = shops.findIndex((obj) => obj._id === '9369');
  // If the object is found in the shops
  if (index !== -1) {
    // Remove the object from its current position
    const movedObject = shops.splice(index, 1)[0]; 
    // Add the removed object to the beginning of the array
    shops.unshift(movedObject);
  }
  
  const items = useMemo(() => {
    let result = [];
    if (shops) {
      result = shops.map(item => {
        return {
          ...item,
          title: item.name,
          url: `/Dashboard/${category}/${item.name}`,
          icon: item.image01ISfile ?? placeholderImage
        };
      });
    }
    return result;
  }, [category]);

  const liveStores =
    inShops.find(c => c.name === 'Portfolio').sub_branches || [];
  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <Row>
          {items.map((item, index) => (
            <Col key={index} md="4" className="mb-4">
              <NavItemComponent
                {...item}
                className="mb-4 h-100 custom-header"
              />
            </Col>
          ))}
        </Row>
        {liveStores.length > 0 && (
          <Portfolio title={'Portfolio'} kind={category} data={liveStores} portfolioFilter={portfolioFilter}/>
        )}
      </div>
    </div>
  );
};

export default Shops;
