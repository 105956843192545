import React, { useState, useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import SubItemComponent from './SubItemComponent';
import StoreAccordion from './FileAndTableAccordion/StoreAcordion';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import Loading from 'components/loading';
import { getErrorMesssage } from 'services/axiosHelpers';
import { dispatchSubContents } from 'redux/subCategoriesSlice';
import RelatedItems from 'components/common/RelatedItems';

const StoreSubCategoryWrapper = ({
  contentId,
  subCategories,
  subContentTree: subTree,
  relatedItems
}) => {
  const { subContents } = useSelector(state => state.subCategories);

  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(subCategories[0]._id);
  const [loadingSub, setLoadingSub] = useState(false);

  useEffect(() => {
    getSubContents(contentId, subCategories, subTree);
  }, []);

  const getSubContent = async (contentId, catId) => {
    try {
      setLoadingSub(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_sub_content'
      )}?contentISbb_q4sg_contentID=${contentId}&sub_categoryISbb_q4sg_content_categoriesID=${catId}`;
      const res = await axios.get(ep);
      return res.data.list;
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      return [];
    } finally {
      setLoadingSub(false);
    }
  };

  const changeKey = val => {
    setSelectedKey(val);
  };

  const getSubContents = async (id, subCategories, subTree) => {
    let tempSubContents = [];
    for (let index = 0; index < subCategories.length; index++) {
      const sub_branch = subCategories[index];
      const resSubContents = await getSubContent(id, sub_branch._id);
      const sortedData = {};
      const historySubTree = subTree?.filter(item =>
        item.startsWith('/history')
      );
      const documentsSubTree = subTree?.filter(item =>
        item.startsWith('/documents')
      );
      const filteredSubTree =
        sub_branch.name === 'Project History'
          ? historySubTree
          : documentsSubTree;
      filteredSubTree?.length > 0 &&
        filteredSubTree.forEach(path => {
          //  Create a property for each path in sortedData
          sortedData[path] = {
            name: path.split('/')[path.split('/').length - 1],
            data: []
          };
        });

      if (Object.keys(sortedData).length > 0) {
        resSubContents.forEach(item => {
          // Add each object to the corresponding array in sortedData
          if (sortedData['/' + item.pathISsmallplaintextbox]) {
            sortedData['/' + item.pathISsmallplaintextbox].data.push(item);
          }
        });
      }

      const nestedData = {};
      for (const [path, { name, data }] of Object.entries(sortedData)) {
        let parentData = [];
        let parentObj = nestedData;
        const pathParts = path.split('/').filter(part => part !== '');

        for (const [index, part] of pathParts.entries()) {
          if (index < pathParts.length - 1) {
            if (!parentObj[part]) {
              parentObj[part] = { name: part, data: [] };
            }
            parentData = parentObj[part].data;
            parentObj = parentObj[part];
          } else {
            parentObj[part] = { name, data };
          }
        }
      }

      const nestedSubContentData = Object.entries(nestedData).map(
        ([key, value]) => ({ [key]: value })
      );

      tempSubContents.push({
        _id: sub_branch._id,
        contents: nestedSubContentData
      });
    }
    dispatch(dispatchSubContents(tempSubContents));
  };

  const selectedSubContents = useMemo(() => {
    const subContentsLocal = [...subContents];
    const selected = subContentsLocal.find(item => item._id == '9222');
    // const selected = subContentsLocal.find(item => item._id == selectedKey);
    if (selected) {
      return selected.contents;
    }
    return [];
  }, [selectedKey, subContents]);

  return (
    <>
      {loadingSub ? (
        <Card.Body className="">
          <Loading style={{ marginTop: 10 }} msg="Loading Sub Content..." />
        </Card.Body>
      ) : (
        <>
          <Row className="mb-4">
            {subCategories.map((s, index) => (
              <Col lg={subCategories.length === 2 ? 6 : 4} key={index}>
                <SubItemComponent
                  {...s}
                  selectedKey={selectedKey}
                  changeKey={changeKey}
                />
              </Col>
            ))}
          </Row>
          {selectedSubContents?.length > 0 && selectedKey === '9223' ? (
            <Row>
              <Col>
                <StoreAccordion data={selectedSubContents} />
              </Col>
            </Row>
          ) : (
            <RelatedItems relatedItems={relatedItems} isStore={true} />
          )}
        </>
      )}
    </>
  );
};

StoreSubCategoryWrapper.propTypes = {
  contentId: PropTypes.string,
  subCategories: PropTypes.array,
  subContentTree: PropTypes.array
};

export default StoreSubCategoryWrapper;
