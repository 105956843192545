import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import { getBase64, getFileObjForAjax } from 'helpers/utils';
import placeholderImage from 'assets/img/placeholder.png';
import { getIcon } from 'helpers/utils';

const ImageUploadField = ({ inputImage, uploadFile, removeFile }) => {
  const {
    acceptedFiles: filesAccepted,
    getRootProps: filesProps,
    getInputProps: filesInputProps
  } = useDropzone({
    accept: ['.jpg', '.png']
  });

  const [file, setFile] = useState(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const fileRemove = () => {
    setFile(null);
    setAcceptedFiles([]);
    uploadFile(null);
    removeFile(null);
  };

  useEffect(async () => {
    if (filesAccepted.length > 0) {
      setFile(null);
      setAcceptedFiles(filesAccepted);
      const data = await getBase64(filesAccepted[0]);
      const f = getFileObjForAjax(filesAccepted[0], data);
      uploadFile(f);
    }
  }, [filesAccepted]);

  useEffect(() => {
    if (inputImage) {
      setFile(inputImage);
    }
  }, []);

  return (
    <>
      <div className={'d-table w-100'}>
        <div
          {...filesProps({
            className: 'dropzone-area py-2 w-50 h-100 d-table-cell'
          })}
        >
          <input {...filesInputProps({ multiple: false })} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
          </Flex>
        </div>
        <div className="w-50 h-100 d-table-cell position-relative">
          {acceptedFiles.length > 0 || file ? (
            <>
              <div
                className="file-remove-icon cursor-pointer"
                onClick={fileRemove}
              >
                <span className="icon">
                  {getIcon(
                    {
                      icon_component: 'ri/RiCloseLine',
                      icon_component_attributes: {
                        fontSize: 20,
                        marginRight: 0
                      }
                    },
                    'red'
                  )}
                </span>
              </div>
              {acceptedFiles.length > 0 && (
                <img
                  src={URL.createObjectURL(acceptedFiles[0])}
                  alt=""
                  className="w-100"
                />
              )}
              {file && <img src={file.uri} alt="" className="w-100" />}
            </>
          ) : (
            <>
              <img src={placeholderImage} alt="" className="w-100" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

ImageUploadField.propTypes = {
  inputImage: PropTypes.object,
  uploadFile: PropTypes.func,
  removeFile: PropTypes.func
};

export default ImageUploadField;
