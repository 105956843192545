import React, { useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from './AccordionItem';
import AccordionTable from './AccordionTable';

const StoreAccordion = ({ data }) => {
  const accordionData = useMemo(() => {
    const dataCopy = data && [...data];
    const itemData = { ...Object.values(Object.values(dataCopy[0]))[0] };
    delete itemData.data;
    delete itemData.name;
    return Object.values(itemData);
  }, [data]);

  // console.log(data, accordionData, 'data in Accordion-DATA ACORDIN MAIN RENDER DSARA');
  return (
    <>
      {data &&
      data[0] &&
      (Object.keys(data[0])[0] === 'history' ||
        Object.keys(data[0])[0] === 'documents') &&
      Object.values(data[0])[0].data?.length > 0 ? (
        <AccordionTable item={Object.values(data[0])[0].data} />
      ) : (
        <Accordion>
          {accordionData?.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{item.name}</Accordion.Header>
              <Accordion.Body>
                {item && item.data?.length > 0 && (
                  <AccordionTable item={item.data} />
                )}
                <AccordionItem data={item} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default StoreAccordion;
