import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { login, logout } from 'redux/authSlice';
import SplashScreenLoading from 'components/loading/SplashScreenLoading';

const AuthRequireAdmin = ({ children }) => {
  const _isMounted = useRef(false);
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [isCheckedSession, setIsCheckedSession] = useState(false);
  const [isSessionValid, setIsSessionValid] = useState(false);
  const isAuthStorage = localStorage.getItem('isAuthenticated');
  const userStorage = localStorage.getItem('user');

  const dispatch = useDispatch();

  const sessionCheck = async () => {
    if (isAuthStorage !== 'done') {
      setIsCheckedSession(true);
      setIsSessionValid(false);
      _isMounted.current && dispatch(logout());
      return;
    }
    try {
      /**
       * Check session
       */
      const sessionEp = `${endpoint.session}`;
      const sessionResponse = await axios.get(sessionEp);
      console.log('------AuthAdmin------sessionResponse', sessionResponse);

      if (sessionResponse.data) {
        const user = sessionResponse.data;
        if (user.user_type === 'Admin') {
          _isMounted.current &&
            dispatch(
              login({
                isAuthenticated: true,
                user
              })
            );

          _isMounted.current && localStorage.setItem('isAuthenticated', 'true');
          _isMounted.current &&
            localStorage.setItem('user', JSON.stringify(user));
          setIsSessionValid(true);
          setIsCheckedSession(true);
          return;
        }
      }
      _isMounted.current && setIsCheckedSession(true);
      _isMounted.current && setIsSessionValid(false);
      _isMounted.current && dispatch(logout());
      return;
    } catch (err) {
      _isMounted.current && setIsCheckedSession(true);
      _isMounted.current && setIsSessionValid(false);
      _isMounted.current && dispatch(logout());
      return;
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (!isAuthenticated) {
      sessionCheck();
    } else {
      setIsSessionValid(true);
      setIsCheckedSession(true);
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  if (isCheckedSession) {
    if (!isSessionValid) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      if (!isAuthenticated) {
        // when redux store is initialized but data in localstorage is still alive, then get data from
        // localstorage and restructure the redux store auth data.
        if (isAuthStorage === 'done' && userStorage.user_type === 'Admin') {
          dispatch(
            login({
              isAuthenticated: true,
              user: JSON.parse(userStorage)
            })
          );
        } else {
          // Redirect them to the /login page, but save the current location they were
          // trying to go to when they were redirected. This allows us to send them
          // along to that page after they login, which is a nicer user experience
          // than dropping them off on the home page.
          return <Navigate to={`/login`} state={{ from: location }} replace />;
        }
      }
    }
  } else {
    return <SplashScreenLoading />;
  }

  return children;
};

AuthRequireAdmin.propTypes = {
  children: PropTypes.object
};

export default AuthRequireAdmin;
