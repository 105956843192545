import React from 'react';
import { Image } from 'react-bootstrap';
// import icon from 'assets/img/logo.png';
import DotSpinner from 'components/common/DotSpinner';
import Logo from 'assets/img/Logo-Sky.png';

const SplashScreenLoading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
        width: '100vw',
        scroll: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999999,
        background: 'white'
      }}
    >
      <Image rounded fluid src={Logo} alt="Logo Skytoolkit" style={{width:'500px'}}/>
      <div className="mt-3">
        <DotSpinner />
      </div>
    </div>
  );
};

export default SplashScreenLoading;
