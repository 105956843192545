import { createSlice } from '@reduxjs/toolkit';
const storedUser = localStorage.getItem('user');
let parsedUser = null;

try {
  parsedUser = JSON.parse(storedUser);
  if (typeof parsedUser === 'string') {
    parsedUser = JSON.parse(parsedUser);
  }
  console.log('Parsed user:', parsedUser);
} catch (e) {
  console.error('Failed to parse user:', storedUser);
}

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  user: parsedUser
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

// Middleware 
export const authMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();
  if (state.auth.isAuthenticated !== undefined) {
    localStorage.setItem('isAuthenticated', state.auth.isAuthenticated);
  }
  if (state.auth.user !== undefined) {
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  }
  return result;
};