import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ContactUsForm from 'components/authentication/ContactUsForm';
import PageTitle from 'components/common/PageTitle';

const ContactUs = () => {
  const title = 'Contact Us';
  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <ContactUsForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactUs;
