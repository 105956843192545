import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import bg from 'assets/img/login_bg.jpg';

const Login = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
      className="login-page"
    >
        <div className="login-form-wrapper">
          <LoginForm />
        </div>
    </div>
  );
};

export default Login;
