import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function DotSpinner() {
  return (
    <div className="dot-spinner">
      <Spinner animation="grow" variant="warning"  size="sm" />
      <Spinner animation="grow" variant="danger" className="mx-3" size="sm" />
      <Spinner animation="grow" variant="info" size="sm" style={{background: '#1798ff'}}/>
    </div>
  );
}

export default DotSpinner;