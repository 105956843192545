import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthSimpleLayout from './AuthSimpleLayout';
import LoginLayout from './LoginLayout';
import FrontLayout from './FrontLayout';
import DashboardLayout from './DashboardLayout';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import ContactUs from 'components/authentication/simple/ContactUs';
import SimpleLogout from 'components/authentication/simple/Logout';
import Signup from 'components/authentication/simple/Signup';
import PasswordReset from 'components/authentication/simple/PasswordReset';
import ResetPassword from 'components/authentication/simple/ResetPassword';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import Landing from 'components/pages/landing/Landing';
import Archive from 'components/pages/archive/Archive';
import Briefing from 'components/pages/briefing/Briefing';
import BriefingForm from 'components/pages/briefing/BriefingForm';
import Contacts from 'components/pages/contacts/Contacts';
import Admin from 'components/pages/admin/Admin';
import Reports from 'components/pages/admin/Reports';
import Projects from 'components/pages/projects/Projects';
import StorePage from 'components/pages/store/StorePage';
import NewsPage from 'components/pages/news/NewsPage';
import Shops from 'components/pages/shops/Shops';
import ShopSub from 'components/pages/shops/ShopSub';
import Product from 'components/pages/shops/Product';
import AuthRequire from 'services/AuthRequire';
import AuthRequireAdmin from 'services/AuthRequireAdmin';
import Sidebar from 'components/Sidebar/Sidebar';
import _debounce from 'lodash.debounce';

const Layout = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    pathname !== '/signup' &&
      pathname !== '/contact' &&
      isAuthenticated === false &&
      navigate('/login');
  }, [pathname, isAuthenticated]);
  return (
    <>
      {isAuthenticated && (
        <div style={{ position: 'fixed', zIndex: 9999 }}>
          <Sidebar pageWrapId={'page-container'} outerContainerId={'main'} />
        </div>
      )}
      <div id="page-container" className="container-xxl">
        <div id="page-inner">
          <Routes>
            {/* //--- FrontLayout Starts  */}
            <Route element={<FrontLayout />}>
              <Route
                path="/"
                element={
                  <AuthRequire>
                    <Landing />
                  </AuthRequire>
                }
              />
              <Route
                path="/home"
                element={
                  <AuthRequire>
                    <Landing />
                  </AuthRequire>
                }
              />
              <Route
                path="/news"
                element={
                  <AuthRequire>
                    <NewsPage />
                  </AuthRequire>
                }
              />
              <Route path="/archive" element={<Archive />} />
              <Route
                path="/briefing"
                element={
                  <AuthRequire>
                    <Briefing />
                  </AuthRequire>
                }
              />
              <Route
                path="/briefing/:category"
                element={
                  <AuthRequire>
                    <BriefingForm />
                  </AuthRequire>
                }
              />
              <Route path="/Contacts" element={<Contacts />} />
              <Route path="/projects" element={<Projects />} />
              <Route
                path="/Dashboard/:category"
                element={
                  <AuthRequire>
                    <Shops />
                  </AuthRequire>
                }
              />
              <Route
                path="/Dashboard/:category/:subCategory1"
                element={
                  <AuthRequire>
                    <ShopSub />
                  </AuthRequire>
                }
              />
              <Route
                path="/Dashboard/:category/:subCategory1/:subCategory2"
                element={
                  <AuthRequire>
                    <ShopSub />
                  </AuthRequire>
                }
              />
              <Route
                path="/Dashboard/:category/:subCategory1/:subCategory2/:subCategory3"
                element={
                  <AuthRequire>
                    <Product />
                  </AuthRequire>
                }
              />
              <Route
                path="/Dashboard/:kind/Portfolio/:countryName/:storeName"
                element={
                  <AuthRequire>
                    <StorePage />
                  </AuthRequire>
                }
              />
              <Route
                path="/admin"
                element={
                  <AuthRequireAdmin>
                    <Admin />
                  </AuthRequireAdmin>
                }
              />
              <Route
                path="/reports"
                element={
                  <AuthRequireAdmin>
                    <Reports />
                  </AuthRequireAdmin>
                }
              />
            </Route>

            <Route element={<ErrorLayout />}>
              <Route path="errors/404" element={<Error404 />} />
              <Route path="errors/500" element={<Error500 />} />
            </Route>
            {/*- ------------- Authentication ---------------------------  */}

            <Route element={<LoginLayout />}>
              <Route path="/login" element={<SimpleLogin />} />
            </Route>

            {/*- ------------- simple ---------------------------  */}
            <Route element={<AuthSimpleLayout />}>
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/logout" element={<SimpleLogout />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/confirm-mail" element={<SimpleConfirmMail />} />
              <Route path="/lock-screen" element={<SimpleLockScreen />} />
            </Route>

            {/* //--- DashboardLayout Starts  */}

            <Route element={<DashboardLayout />}>{/*Dashboard*/}</Route>

            {/* //--- DashboardLayout end  */}

            {/* <Navigate to="/errors/404" /> */}
            <Route path="*" element={<Navigate to="/errors/404" replace />} />
          </Routes>
          <ToastContainer
            closeButton={CloseButton}
            icon={false}
            position={toast.POSITION.BOTTOM_LEFT}
          />
        </div>
      </div>
    </>
  );
};

export default Layout;
