import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Image, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import { getIcon } from 'helpers/utils';
import { saveAs } from 'file-saver';

const FalconImageModal = ({ show, src, size = 'lg', handleClose }) => {
  const _isMounted = useRef(false);

  const downloadImage = () => {
    console.log(src);
    saveAs(src, 'image.jpg');
  };

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size={size}
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          <span className="cursor-pointer" onClick={() => downloadImage()}>
            {getIcon({
              icon_component: 'fi/FiDownload',
              icon_component_attributes: { fontSize: 20, marginRight: 0 }
            })}
          </span>
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Flex alignItems="center" justifyContent="center">
            <Image fluid src={src} className="modal-image w-100" />
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};

FalconImageModal.propTypes = {
  show: PropTypes.bool,
  src: PropTypes.string,
  size: PropTypes.string,
  handleClose: PropTypes.func
};

export default FalconImageModal;
