import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import StoreAccordion from './FileAndTableAccordion/StoreAcordion';

const ProjectHistory = () => {
  const { subContents } = useSelector(state => state.subCategories);
  const projectHistoryData = useMemo(() => {
    const subContentsLocal = [...subContents];
    const selected = subContentsLocal.find(item => item._id == '9223');
    if (selected) {
      return selected.contents;
    }
    return [];
  }, [subContents]);

  return (
    <>
      {projectHistoryData.length > 0 ? (
        <fieldset className={'form-legend-wrapper p-4 cursor-pointer border'}>
          <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
            Project History
          </legend>
          <Row>
            <Col>
              <StoreAccordion data={projectHistoryData} />
            </Col>
          </Row>
        </fieldset>
      ) : null}
    </>
  );
};
export default ProjectHistory;
