import React, { Fragment, useMemo, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import PageTitle from 'components/common/PageTitle';
import Flex from 'components/common/Flex';
import NewsItemComponent from 'components/common/NewsItemComponent';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/it'; 
import 'moment/locale/de'; 
const NewsPage = () => {
  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);
  const title = 'News';
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const catsFromState = category_tree.find(c => c.name === 'News').sub_branches;
  moment.locale(languageCode==='GB' ? 'en': languageCode);
  const kinds = [
    {
      _id: 'all',
      name: 'All'
    }
    // ...catsFromState
  ];

  const newsItems = useMemo(() => {
    let result = [];
    catsFromState.forEach(cat => {
      const newses = cat.content_list.map(n => {
        return {
          _id: n._id,
          kind: cat._id,
          type: 'main',
          title: languageCode === 'GB' ? n.name : translations[n._id][languageCode].name,
          content: languageCode === 'GB' ? n.summaryISsmallplaintextbox : translations[n._id][languageCode].summaryISsmallplaintextbox,
          date: moment(n._dateadded).format('D MMMM YYYY'),
          time: moment(n._dateadded).format('h:mm:ss a'),
          team: 'Sky Retail',
          image: n.image01ISfile,
          favorite: false,
          reply: true
        };
      });
      result = [...result, ...newses];
    });

    return result;
  }, [category_tree, languageCode]);

  const [kind, setKind] = useState('all');

  const selectedNewsItems = useMemo(() => {
    let result = newsItems;
    if (kind === 'all') {
      return result;
    }
    result = newsItems.filter(news => news.kind === kind);
    return result;
  }, [newsItems, kind, languageCode]);

  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-3 mb-6">
        <Tab.Container
          activeKey={kind}
          onSelect={k => setKind(k)}
          id="countries-tab"
          transition={false}
        >
          <Flex justifyContent="between" alignItems="center" className="d-none">
            <Nav variant="tabs" className="flex-row">
              {kinds.map((c, index) => (
                <Fragment key={index}>
                  <Nav.Item>
                    <Nav.Link eventKey={c._id} className="fw-semi-bold fs-1">
                      {c.name}
                    </Nav.Link>
                  </Nav.Item>
                </Fragment>
              ))}
            </Nav>
          </Flex>
          <Tab.Content>
            {kinds.map((c, index) => (
              <Fragment key={index}>
                <Tab.Pane eventKey={c._id} title={c.name} className="p-3 pt-0">
                  {selectedNewsItems.map((item, index) => (
                    <Fragment key={index}>
                      <NewsItemComponent {...item} className="mb-4 x2" />
                    </Fragment>
                  ))}
                </Tab.Pane>
              </Fragment>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default NewsPage;
