import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { replaceSpacesWithDashes } from 'helpers/utils';

const RelatedItemsTable = ({ relatedItemsTableData }) => {
  const { t } = useTranslation();

  const relatedItemsColumns = [
    {
      accessor: 'name',
      Header: t('Title'),
      Cell: rowData => {
        const { name, url } = rowData.row.original;
        return (
          <>
            <Link
              className="cursor-pointer"
              target="_blank"
              to={`${replaceSpacesWithDashes(
                url
              )}?tab=${replaceSpacesWithDashes(name)}`}
            >
              {name}
            </Link>
          </>
        );
      }
    }
  ];

  return (
    <div className="">
      <AdvanceTableWrapper
        columns={relatedItemsColumns}
        data={relatedItemsTableData}
        sortable
        pagination
        perPage={10}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
          rowClassName="align-middle white-space-nowrap fs-0"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0'
          }}
        />
      </AdvanceTableWrapper>
    </div>
  );
};
export default RelatedItemsTable;
