import React from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import Footer from 'components/footer/Footer';

const LoginLayout = () => {
  return (
    <div className={''}>
      <div className={classNames('content')}>
        <NavbarTop />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default LoginLayout;
