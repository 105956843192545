import React, { Fragment } from 'react';
import PageTitle from 'components/common/PageTitle';
import Flex from 'components/common/Flex';
import NavItemComponent from 'components/common/NavItemComponent';
import shopIcon from 'assets/img/nav-items/sky.png';
import { useTranslation } from 'react-i18next';

const Archive = () => {
  const { t } = useTranslation();
  const items = [
    {
      title: 'Furniture',
      url: '/furniture',
      icon: shopIcon
    },
    {
      title: 'Stores',
      url: '/stores',
      icon: shopIcon
    }
  ];
  const title = 'Archive';
  const subTitle = 'Mid Mall';
  return (
    <div>
      <div className="fw-semi-bold fs-1 page-sub-title">{t(subTitle)}</div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <Flex
          justifyContent="between"
          alignItems="center"
          wrap={'wrap'}
          className="mb-2"
        >
          {items.map((item, index) => (
            <Fragment key={index}>
              <NavItemComponent {...item} className="mb-4 x2" />
            </Fragment>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default Archive;
