import React, { useState } from 'react';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getURLFromName, getSubMenuURLFromName } from 'helpers/utils';
import { slide as Menu } from 'react-burger-menu';
import PropTypes from 'prop-types';
import { replaceSpacesWithDashes } from 'helpers/utils';

const Sidebar = ({ pageWrapId, outerContainerId }) => {
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const menuItems =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleStateChange = state => {
    setMenuOpen(state.isOpen);
  };

  const menuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Menu
        isOpen={menuOpen}
        onStateChange={state => handleStateChange(state)}
        pageWrapId={pageWrapId}
        outerContainerId={outerContainerId}
        className="sidebar-menu"
      >
        <div className="menu-wrapper">
          {menuItems.map((menu, index) => (
            <div key={index} className="pb-2">
              <Link
                to={getURLFromName(menu.name)}
                className="text-white"
                onClick={menuClose}
              >{`${t(menu.name)}`}</Link>
              {menu.sub_branches && menu.sub_branches.length > 0 && (
                <div className="sub-menu ms-2">
                  {menu.sub_branches.map((s, i) => (
                    <div key={i} className="pb-1">
                      <Link
                        to={replaceSpacesWithDashes(`${getSubMenuURLFromName(menu.name, s.name)}`)}
                        className="text-white"
                        onClick={menuClose}
                      >{`${t(s.name)}`}</Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </Menu>
    </>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  pageWrapId: PropTypes.string,
  outerContainerId: PropTypes.string
};

export default Sidebar;
