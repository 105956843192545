import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css';

const Overlay = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="overlay">
      <div className="spinner-container">
        <Spinner animation="border" variant="primary" />
      </div>
    </div>
  );
};

export default Overlay;