import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import { getBase64, getFileObjForAjax } from 'helpers/utils';
import placeholderImage from 'assets/img/placeholder.png';
import { getIcon } from 'helpers/utils';

const DocumentUploadField = ({
  inputFile,
  uploadFile,
  onPrint,
  removeFile
}) => {
  // const { t } = useTranslation();
  const {
    acceptedFiles: filesAccepted,
    getRootProps: filesProps,
    getInputProps: filesInputProps
  } = useDropzone({
    accept: ['.pdf', '.docx', '.txt']
  });

  const [file, setFile] = useState(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const fileRemove = () => {
    setAcceptedFiles([]);
    setFile(null);
    uploadFile(null);
    removeFile();
  };

  useEffect(() => {
    if (inputFile) {
      setFile(inputFile);
    }
  }, []);

  useEffect(async () => {
    if (filesAccepted.length > 0) {
      setFile(null);
      setAcceptedFiles(filesAccepted);
      const data = await getBase64(filesAccepted[0]);
      const f = getFileObjForAjax(filesAccepted[0], data);
      uploadFile(f);
    }
  }, [filesAccepted]);

  return (
    <>
      <div className={'d-table w-100'}>
        <div
          {...filesProps({
            className: 'dropzone-area py-2 w-50 h-100 d-table-cell'
          })}
        >
          <input {...filesInputProps({ multiple: false })} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
          </Flex>
        </div>
        <div className="w-50 h-100 ps-2 d-table-cell position-relative">
          {acceptedFiles.length > 0 || file ? (
            <>
              <div
                className="file-remove-icon cursor-pointer"
                onClick={fileRemove}
              >
                <span className="icon">
                  {getIcon(
                    {
                      icon_component: 'ri/RiCloseLine',
                      icon_component_attributes: {
                        fontSize: 20,
                        marginRight: 0
                      }
                    },
                    'red'
                  )}
                </span>
              </div>
              {acceptedFiles.length > 0 && (
                <div
                  className="cursor-pointer mb-3"
                  onClick={() => onPrint(acceptedFiles[0])}
                >
                  <Flex justifyContent="end">
                    <span className="icon">
                      {getIcon(
                        {
                          icon_component: 'fa/FaFileAlt',
                          icon_component_attributes: {
                            fontSize: 30,
                            marginRight: 0
                          }
                        },
                        'blue'
                      )}
                    </span>
                  </Flex>
                </div>
              )}
              {file && (
                <div
                  className="cursor-pointer mb-3"
                  onClick={() => onPrint(file)}
                >
                  <Flex justifyContent="end">
                    <span className="icon">
                      {getIcon(
                        {
                          icon_component: 'fa/FaFileAlt',
                          icon_component_attributes: {
                            fontSize: 30,
                            marginRight: 0
                          }
                        },
                        'blue'
                      )}
                    </span>
                  </Flex>
                </div>
              )}
            </>
          ) : (
            <>
              <img src={placeholderImage} alt="" className="w-100" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

DocumentUploadField.propTypes = {
  inputFile: PropTypes.object,
  uploadFile: PropTypes.func,
  onPrint: PropTypes.func,
  removeFile: PropTypes.func
};

export default DocumentUploadField;
