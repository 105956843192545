export function truncateFileName(fileName) {
    const maxLength = 34;
    const middle = ' ... ';
  
    if (fileName.length <= maxLength) {
      return fileName;
    }
  
    const halfLength = Math.floor((maxLength - middle.length) / 2);
    const start = fileName.substring(0, halfLength);
    const end = fileName.substring(fileName.length - halfLength, fileName.length);
  
    return start + middle + end;
  }

  export function truncateFolderName(fileName) {
    const maxLength = 50;
    const middle = ' ... ';
  
    if (fileName.length <= maxLength) {
      return fileName;
    }
  
    const halfLength = Math.floor((maxLength - middle.length) / 2);
    const start = fileName.substring(0, halfLength);
    const end = fileName.substring(fileName.length - halfLength, fileName.length);
  
    return start + middle + end;
  }