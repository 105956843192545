import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from 'components/common/PageTitle';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { useTranslation } from 'react-i18next';
import { getIcon } from 'helpers/utils';

const ProductDocuments = ({ data }) => {
  const { t } = useTranslation();
  const { title, documents:tableData=[] } = data;

  const onFile = (url, type) => {
    if (!url) {
      return;
    }
    console.log(type);
    window.open(url, '_blank');
  };

  const columns = [
    {
      accessor: 'name',
      Header: t(''),
      Cell: rowData => {
        const { name, pdf, dwg } = rowData.row.original;
        return (
          <>
            <div
              className="hover-grey"
              onClick={() => {
                pdf ? onFile(pdf, 'pdf') : onFile(dwg, 'dwg');
              }}
            >
              {name}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'pdf',
      Header: t('PDF'),
      Cell: rowData => {
        const { pdf } = rowData.row.original;
        return (
          <>
            <div
              style={{ opacity: pdf ? 1 : 0.5 }}
              className="cursor-pointer text-center"
              onClick={() => onFile(pdf, 'pdf')}
            >
              {getIcon({
                icon_component: 'fi/FiFileText',
                icon_component_attributes: { fontSize: 30, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'dwg',
      Header: t('DWG'),
      Cell: rowData => {
        const { dwg } = rowData.row.original;
        return (
          <div
            style={{ opacity: dwg ? 1 : 0.5 }}
            className="cursor-pointer text-center"
            onClick={() => onFile(dwg, 'dwg')}
          >
            {getIcon({
              icon_component: 'fi/FiFileText',
              icon_component_attributes: { fontSize: 30, marginRight: 0 }
            })}
          </div>
        );
      }
    }
  ];  
  return (
    <div>
      <PageTitle title={title} />
      {tableData.length > 0 && <div className="page-content pt-6 mb-6">
        <div className="mb-6">
          <div className="extended-table">
            <AdvanceTableWrapper
              columns={columns}
              data={tableData}
              sortable
              pagination
              perPage={10}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
                rowClassName="align-middle white-space-nowrap fs-0"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
              />
            </AdvanceTableWrapper>
          </div>
        </div>
      </div>}
    </div>
  );
};

ProductDocuments.propTypes = {
  data: PropTypes.object
};

export default ProductDocuments;
