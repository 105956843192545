import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
//import team3 from 'assets/img/team/3.jpg';
//import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/authSlice';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import PropTypes from 'prop-types';
import { getIcon } from 'helpers/utils';
import { getErrorMesssage } from 'services/axiosHelpers';

const ProfileDropdown = ({ isAuthenticated, isAdmin }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const onLogOut = async () => {
    try {
      /**
       * Log out
       */
      const logoutEp = `${endpoint.logout}`;
      const logoutResponse = await axios.get(logoutEp);
      console.log('logoutResponse', logoutResponse);
      localStorage.clear();
      dispatch(logout());
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    }
  };

  return (
    <>
      {isAuthenticated ? (
        <Dropdown navbar={true} as="li">
          <Dropdown.Toggle
            bsPrefix="toggle"
            as={Link}
            to="#!"
            className="pe-0 ps-0 nav-link"
          >
            <Flex justifyContent="between" alignItems="center" className="">
              <span className="me-2">{`${user?.first_name}`}</span>
              {/* <Avatar src={team3} /> */}
              <span>
                {getIcon({
                  icon_component: 'fa/FaUser',
                  icon_component_attributes: { fontSize: 20, marginRight: 0 }
                })}
              </span>
            </Flex>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              {isAdmin && (
                <>
                  <Dropdown.Item as={Link} to="/admin">
                    {t('Admin Area')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to="/reports">
                    {t('Reports')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item>
                <Flex justifyContent="start" alignItems="center" className="">
                  <span className="" onClick={onLogOut}>
                    {t('Sign out')}
                  </span>
                </Flex>
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Dropdown navbar={true} as="li">
          <Dropdown.Toggle
            bsPrefix="toggle"
            as={Link}
            className="pe-0 ps-0 nav-link"
          >
            <Flex justifyContent="between" alignItems="center" className="">
              <span className="me-2">{t('Log In')}</span>
            </Flex>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
              <Dropdown.Item as={Link} to="/login">
                {t('Log in')}
              </Dropdown.Item>
              {/*<Dropdown.Divider />
              <Dropdown.Item as={Link} to="/password-reset">
                {t('Password Reset')}
              </Dropdown.Item>*/}
              <Dropdown.Divider />
              <Dropdown.Item as={Link} to="/signup">
                {t('Request Access')}
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

ProfileDropdown.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default ProfileDropdown;
