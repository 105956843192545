import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMobileScreenSize from 'hooks/useMobileScreenSize';

const PageTitle = ({ title, className, children }) => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSize();
  const fontSizeClass = isMobile ? 'fs-6' : 'fs-7'
  return (
    <div className={classNames(className, 'page-title text-center', fontSizeClass)}>
      {typeof title === 'string' ? t(title) : children}
    </div>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default PageTitle;
