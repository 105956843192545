import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';

const SubItemComponent = ({
  _id,
  name,
  url,
  icon,
  selectedKey,
  className,
  changeKey,
}) => {
  const { t } = useTranslation();
  const onClickItem = _id => {
    changeKey(_id);
    if (url) {
      window.open(url, '_blank');
    }
  };
  return (
    <fieldset
      className={classNames(
        className,
        'nav-item-comp p-4 text-center cursor-pointer h-100 border',
        {
          active: selectedKey === _id
        }
      )}
      style={{ opacity: selectedKey === _id ? 1 : 0.5 }}
      onClick={() => onClickItem(_id)}
    >
      <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
        {name === 'Project History' ? 'Shop Fit Information' : t(name)}
      </legend>
      <Flex justifyContent="center" alignItems="center" className="h-100">
        <div className="item-icon hover-overlay-effect">
          <Image rounded fluid src={icon} alt="" />
        </div>
      </Flex>
    </fieldset>
  );
};

SubItemComponent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  _id: PropTypes.string,
  url: PropTypes.string,
  selectedKey: PropTypes.string,
  icon: PropTypes.string,
  changeKey: PropTypes.func
};

export default SubItemComponent;
