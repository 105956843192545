import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const AppBody = () => {
  const appsetting = useSelector(state => state.appsetting);
  const {
    title,
    favicon_16ISfile,
    favicon_32ISfile,
    favicon_192ISfile,
    env_files
  } = appsetting || {};

  const loadScript = src => {
    let bodyEle = document.getElementsByTagName('body');
    let tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    bodyEle[0].appendChild(tag);
  };

  useEffect(() => {
    if (env_files?.page_bottom && env_files?.page_bottom.length > 0) {
      env_files?.page_bottom.forEach(item => {
        loadScript(item.url);
      });
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`${title}`}</title>
          {favicon_16ISfile?.uri && (
            <link
              rel="icon"
              type={favicon_16ISfile.type || 'image/png'}
              href={favicon_16ISfile.uri}
              sizes="16x16"
            />
          )}
          {favicon_32ISfile?.uri && (
            <link
              rel="icon"
              type={favicon_32ISfile.type || 'image/png'}
              href={favicon_32ISfile.uri}
              sizes="32x32"
            />
          )}
          {favicon_192ISfile?.uri && (
            <link
              rel="icon"
              type={favicon_192ISfile.type || 'image/png'}
              href={favicon_192ISfile.uri}
              sizes="192x192"
            />
          )}
          {env_files?.page_top &&
            env_files?.page_top.length > 0 &&
            env_files?.page_top.map((item, index) => (
              <link rel="stylesheet" href={item.url} key={index} />
            ))}
        </Helmet>
      </HelmetProvider>
      <Layout />
    </>
  );
};

export default AppBody;
