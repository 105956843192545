import React, { useState, useEffect, useRef } from 'react';
import './quick-links.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { getErrorMesssage } from 'services/axiosHelpers';
import { useDispatch } from 'react-redux';
import Loading from 'components/loading';
import { Card } from 'react-bootstrap';
import { logout } from 'redux/authSlice';

const QuickLinks = () => {
  const _isMounted = useRef(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [quickLinks, setQuickLinks] = useState([]);
  const title = 'Quick Links';

  const removeLink = link => {
    deleteLink(link);
  };

  const onAddLink = () => {
    const url = decodeURI(window.location.pathname);
    const name = decodeURI(window.location.pathname.split('/').pop());
    if (isExist(url)) {
      return;
    }
    const link = {
      name,
      url
    };
    addLink(link);
  };

  const getLinks = async () => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.quickLinks}`;
      const res = await axios.get(ep);
      if (res.data) {
        _isMounted.current && setQuickLinks(res.data);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const deleteLink = async link => {
    try {
      const ep = `${endpoint.quickLinks}/${link._id}`;
      const res = await axios.delete(ep);
      if (res.data) {
        _isMounted.current && setup();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } 
  };

  const addLink = async link => {
    try {
      // _isMounted.current && setLoading(true);
      const ep = `${endpoint.quickLinks}`;
      const res = await axios.post(ep, link);
      if (res.data) {
        _isMounted.current && setup();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      // _isMounted.current && setLoading(false);
    }
  };

  const isExist = url => {
    let result = quickLinks.find(l => l.url === url);
    if (!result) {
      return false;
    }
    return true;
  };

  const setup = () => {
    getLinks();
  };
  useEffect(() => {
    _isMounted.current = true;
    if (isAuthenticated) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [isAuthenticated]);

  return (
    <fieldset className="nav-card quicklinks-wrapper">
      <legend className="nav-card-title fs-1 fw-semi-bold">{t(title)}</legend>
      <div className="nav-card-inner">
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} />
          </Card.Body>
        ) : (
          <>
            <div className="quick-links">
              {quickLinks.map((link, index) => (
                <div key={index} className="my-2">
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="link-item"
                  >
                    <Link className="card-link d-block" to={link.url}>
                      <Flex
                        justifyContent="start"
                        alignItems="center"
                        className="py-1 fw-bold"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={'circle'}
                            className="me-2"
                            size="2xs"
                          />
                        </span>
                        <span className="hover-underline-effect">
                          {t(link.name)}
                        </span>
                      </Flex>
                    </Link>
                    {link._id && (
                      <div
                        className="cursor-pointer delete-link"
                        onClick={() => removeLink(link)}
                      >
                        <FontAwesomeIcon icon={'close'} color="red" size="sm" />
                      </div>
                    )}
                  </Flex>
                </div>
              ))}
            </div>
            <div className="card-link" onClick={onAddLink}>
              <Flex
                justifyContent="start"
                alignItems="center"
                className="mb-3 fw-bold"
              >
                <span>
                  <FontAwesomeIcon icon={'plus'} className="me-2" size="xs" />
                </span>
                <span className="hover-underline-effect">
                  {t('Add current page')}
                </span>
              </Flex>
            </div>
          </>
        )}
      </div>
    </fieldset>
  );
};

export default QuickLinks;
