import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import { getBase64, getFileObjForAjax } from 'helpers/utils';

const FileFormField = ({ uploadFile }) => {
  const { t } = useTranslation();
  const {
    acceptedFiles: filesAccepted,
    getRootProps: filesProps,
    getInputProps: filesInputProps
  } = useDropzone();
  useEffect(async () => {
    if (filesAccepted.length > 0) {
      const data = await getBase64(filesAccepted[0]);
      const file = getFileObjForAjax(filesAccepted[0], data);
      uploadFile(file);
    }
  }, [filesAccepted]);

  return (
    <>
      <div
        {...filesProps({
          className: 'dropzone-area py-2'
        })}
      >
        <input {...filesInputProps({ multiple: false })} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">{t('Drop your files here')}</p>
        </Flex>
      </div>
      <div className="mt-3">
        {filesAccepted.length > 0 && (
          <>
            <h6>File</h6>
            <ul>
              <li>
                {filesAccepted[0].path} - {filesAccepted[0].size} bytes
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

FileFormField.propTypes = {
  uploadFile: PropTypes.func
};

export default FileFormField;
