import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { login, logout } from 'redux/authSlice';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { getErrorMesssage } from 'services/axiosHelpers';
import PageTitle from 'components/common/PageTitle';

const LoginForm = () => {
  const currentUrl = window.location.hostname;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/';
  const title = "Let's get started";
  // const forgottenText = 'Forgotten your email, username or password?';
  const forgottenText = 'Request a password reset link';
  const { t } = useTranslation();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [isWorking, setIsWorking] = useState(false);

  const onLogout = async () => {
    setIsWorking(true);
    try {
      /**
       * Log out
       */
      const logoutEp = `${endpoint.logout}`;
      await axios.get(logoutEp);
      // console.log('1...logoutResponse', logoutResponse);
      dispatch(logout());
      localStorage.clear();

      await onLogin();
    } catch (err) {
      console.log('-22222222----logout err--------', err);
      const message = getErrorMesssage(err);

      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      setIsWorking(false);
    }
  };

  const onLogin = async () => {
    setIsWorking(true);
    try {
      /**
       * Log in
       */
      const ep = `${endpoint.login}`;
      const data = {
        username: formData.email,
        password: formData.password
      };
      await axios.post(ep, data);
      // console.log('2...loginResponse', loginResponse);

      await onGetSession();
    } catch (err) {
      console.log('login err--------', err);
      dispatch(
        setError({
          isShow: true,
          title: 'Incorrect Email or Password',
          message: 'Please submit correct Email and Password'
        })
      );
    } finally {
      setIsWorking(false);
    }
  };

  const onGetSession = async () => {
    setIsWorking(true);
    try {
      /**
       * Check session
       */
      const sessionEp = `${endpoint.session}`;
      const sessionResponse = await axios.get(sessionEp);
      // console.log('3...sessionResponse', sessionResponse);

      const user = sessionResponse.data;
      dispatch(
        login({
          isAuthenticated: true,
          user
        })
      );

      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('user', JSON.stringify(user));
    } catch (err) {
      console.log('session err--------', err);
      if (err?.response?.status === 401) {
        navigate('/login');
        return;
      }
      // const message = getErrorMesssage(err);

      // dispatch(
      //   setError({
      //     isShow: true,
      //     title: 'Error',
      //     message
      //   })
      // );
    } 
    finally {
      setIsWorking(false);
      navigate(from, { replace: true });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    onLogout();
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const passwordResetLink = useMemo(() => {
    if (currentUrl === 'dev.skytoolkit.net') {
      return 'https://skytoolkit-dev.blueboxonline.co.uk/?password_reset';
    } else if (currentUrl === 'www.skytoolkit.net') {
      return 'https://skytoolkit.blueboxonline.co.uk/?password_reset';
    }
    return 'https://skytoolkit-dev.blueboxonline.co.uk/?password_reset';
  }, [currentUrl]);

  return (
    <>
      <Row className="bg-white login-form">
        <Col>
          <PageTitle title={title} />
          <Form onSubmit={handleSubmit}>
            <div className="fs-0 my-3">
              {t('Please enter your email or username')}
            </div>
            <fieldset className={'form-legend-item-wrapper mb-3'}>
              <legend className="item-title fs--1">
                {t('Email or Username')}
              </legend>
              <Form.Group className="mb-0">
                <Form.Control
                  placeholder={'Email address'}
                  value={formData.email}
                  name="email"
                  onChange={handleFieldChange}
                  type="email"
                />
              </Form.Group>
            </fieldset>

            <fieldset className={'form-legend-item-wrapper mb-3'}>
              <legend className="item-title fs--1">{t('Password')}</legend>
              <Form.Group className="mb-0">
                <Form.Control
                  placeholder={'Password'}
                  value={formData.password}
                  name="password"
                  onChange={handleFieldChange}
                  type="password"
                />
              </Form.Group>
            </fieldset>

            <div className="text-center fw-semi-bold w-100 mb-3">
              <Link
                to={passwordResetLink}
                className="link text-decoration-underline"
              >
                {t(forgottenText)}
              </Link>
            </div>

            <Form.Group>
              <Button
                type="submit"
                color="primary"
                className="my-3 w-100"
                disabled={!formData.email || !formData.password || isWorking}
              >
                {t('Continue')}
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default LoginForm;
