/**
 * Local
 */
// export const reCAPTCHA_SITE_KEY = '6LdQ90glAAAAAFW_I_xVECcTMzWLO9fOihvM-KYu';
// export const REACT_reCAPTCHA_SECRET_KEY =
//   '6LdQ90glAAAAAOBnTeMjhrUfTzDFndooMLartxuH';

/**
 * Live
 */
export const reCAPTCHA_SITE_KEY = '6LdyETgmAAAAADV6DjycplzGmIeMZNB1CvaRN2LB';
export const REACT_reCAPTCHA_SECRET_KEY =
  '6LdyETgmAAAAAErk7IoP-a_nMufxYl42MeRqsqQn';

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'BLOCK_ALIGNMENT_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: 'Align Left', style: 'ALIGN_LEFT' },
    { label: 'Align Center', style: 'ALIGN_CENTER' },
    { label: 'Align Right', style: 'ALIGN_RIGHT' },
    { label: 'Align Justify', style: 'ALIGN_JUSTIFY' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};
