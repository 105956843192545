import React from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
// import { useTranslation } from 'react-i18next';
import { getIcon, getFileExtension } from 'helpers/utils';
import { truncateFileName } from './helpers';
// import useMobileScreenSize from 'hooks/useMobileScreenSize';
import useTabletScreenSize from 'hooks/useTabletScreenSize';
import useSmallTabletScreenSize from 'hooks/useSmallTabletScreenSize';

const AccordionTable = ({ item }) => {
  // const { t } = useTranslation();
  // const isMobile = useMobileScreenSize();
  const isTablet = useTabletScreenSize();
  const isSmallTablet = useSmallTabletScreenSize();
  const onFile = (url, type) => {
    if (!url) {
      return;
    }
    console.log(type);
    window.open(url, '_blank');
  };

  const setTableData = item => {
    const result =
      item?.length > 0
        ? item.map(doc => {
            const uri = doc?.document01ISfile?.uri;
            const extension = uri ? getFileExtension(uri) : '';
            return {
              _id: doc._id,
              name: doc.data
                ? doc.name.split('/')[doc.name.split('/').length - 1]
                : doc.name,
              pdf: !['dwg', 'dxf'].includes(extension) ? uri : null,
              dwg: ['dwg', 'dxf'].includes(extension) ? uri : null
            };
          })
        : [];
    return result;
  };
  const columns = [
    {
      accessor: 'name',
      // Header: t(''),
      Header: '',
      Cell: rowData => {
        const { name, pdf, dwg } = rowData.row.original;
        return (
          <>
            <div
              className="hover-grey"
              title={name}
              onClick={() => {
                pdf ? onFile(pdf, 'pdf') : onFile(dwg, 'dwg');
              }}
            >
              {/* {console.log(isTablet,
             '(name.length > 35 && isSmallTablet)')} */}
              {(name.length > 35 && isSmallTablet) ||
              (name.length > 45 && isTablet) || name.length > 70
                ? truncateFileName(name)
                : name}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'pdf',
      Header: 'PDF',
      Cell: rowData => {
        const { pdf } = rowData.row.original;
        return (
          <>
            <div
              style={{ opacity: pdf ? 1 : 0.5 }}
              className="cursor-pointer text-center"
              onClick={() => onFile(pdf, 'pdf')}
            >
              {getIcon({
                icon_component: 'fi/FiFileText',
                icon_component_attributes: { fontSize: 30, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'dwg',
      Header: 'DWG',
      Cell: rowData => {
        const { dwg } = rowData.row.original;
        return (
          <div
            style={{ opacity: dwg ? 1 : 0.5 }}
            className="cursor-pointer text-center"
            onClick={() => onFile(dwg, 'dwg')}
          >
            {getIcon({
              icon_component: 'fi/FiFileText',
              icon_component_attributes: { fontSize: 30, marginRight: 0 }
            })}
          </div>
        );
      }
    }
  ];

  return (
    <div className="extended-table mb-3">
      <AdvanceTableWrapper
        columns={columns}
        data={setTableData(item)}
        sortable={false}
        perPage={item.length + 1}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
          rowClassName="align-middle white-space-nowrap fs-0"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0'
          }}
        />
      </AdvanceTableWrapper>
    </div>
  );
};
export default AccordionTable;
