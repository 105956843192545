import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useTranslation } from 'react-i18next';
import { getIcon } from 'helpers/utils';
import { useNavigate } from 'react-router';

const MediaSearchContent = ({ item }) => {
  return (
    <Dropdown.Item className="px-card py-2" as={Link} to={item.url}>
      <Flex alignItems="center">
        {item.file && (
          <div className="file-thumbnail">
            <img src={item.img} alt="" className={item.imgAttrs.class} />
          </div>
        )}
        {item.icon && (
          <Avatar src={item.icon.img} size="l" className={item.icon.status} />
        )}

        <div className="ms-2">
          <h6 className="mb-0">{item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};

const SearchBox = ({ autoCompleteItem }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchResult = useMemo(() => {
    if (!searchInputValue) {
      return [];
    }
    const result = autoCompleteItem.filter(item => {
      if (!item) {
        return false;
      }
      let title = t(item.title);
      title = title.toLocaleLowerCase();
      let key = searchInputValue.toLowerCase();

      let text = t(item.text);
      text = text.toLocaleLowerCase();

      return title.includes(key) || text.includes(key);
    });
    // console.log('----search', searchInputValue, result);
    return result;
  }, [searchInputValue]);

  const onClickItem = url => {
    setDropdownOpen(false);
    navigate(url);
  };

  useEffect(() => {
    if (searchInputValue) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [searchInputValue]);

  return (
    <Dropdown show={true} className="search-box">
      <Dropdown.Toggle
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div
              className="search-box-close-btn-container"
              // style={{ right: '10px', top: '8px' }}
            >
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => setSearchInputValue('')}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      {dropdownOpen && (
        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
            {isIterableArray(searchResult) ? (
              <>
                <Dropdown.Header
                  as="h6"
                  className="px-card pt-0 pb-2 fw-medium"
                >
                  {`${t('Search Results')}:`}
                </Dropdown.Header>
                {searchResult.map((item, index) => (
                  <Dropdown.Item
                    className="fs-0 px-card py-1"
                    key={index}
                    onClick={() => onClickItem(item.url ? item.url : '#')}
                  >
                    <Flex alignItems="center">
                      <div className="fs--1 me-2">
                        {item.type === 'category' ? (
                          <span>
                            {getIcon({
                              icon_component: 'fa/FaFolder',
                              icon_component_attributes: {
                                fontSize: 16,
                                marginRight: 0
                              }
                            })}
                          </span>
                        ) : (
                          <span className="">
                            {getIcon(
                              {
                                icon_component: 'fi/FiFile',
                                icon_component_attributes: {
                                  fontSize: 16,
                                  marginRight: 0
                                }
                              },
                              `#344050`
                            )}
                          </span>
                        )}
                      </div>
                      <div className="fs--1">{t(item.title)}</div>
                    </Flex>
                  </Dropdown.Item>
                ))}
              </>
            ) : (
              <p className="fs-1 fw-bold text-center mb-0">
                {`${t('No Result Found')}.`}
              </p>
            )}
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

MediaSearchContent.propTypes = {
  item: PropTypes.shape({
    catagories: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      img: PropTypes.string.isRequired,
      size: PropTypes.string,
      status: PropTypes.string
    }),
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    time: PropTypes.string,
    file: PropTypes.bool,
    imgAttrs: PropTypes.shape({
      class: PropTypes.string
    })
  }).isRequired
};

SearchBox.propTypes = {
  autoCompleteItem: PropTypes.arrayOf(
    PropTypes.shape(MediaSearchContent.propTypes.item)
  )
};

export default SearchBox;
