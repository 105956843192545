import React from 'react';
import PageTitle from 'components/common/PageTitle';
// import NavItemComponent from 'components/common/NavItemComponent';
// import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import placeholderImage from 'assets/img/placeholder.png';
import { useTranslation } from 'react-i18next';

const Briefing = () => {
  const { t } = useTranslation();

  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const title = 'Briefing';
  const itemsCate = sub_branches.find(s => s.name === 'Briefing');
  const inItems = itemsCate ? itemsCate.sub_branches || [] : [];
  const items = inItems.map(item => {
    return {
      ...item,
      title: item.name,
      url: `/Briefing/${item.name}`,
      icon: item.image01ISfile ?? placeholderImage
    };
  });

  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        <div style={{ color: 'gray', textAlign: 'center', fontSize: '16pt' }}>
          {t('This section is under construction')}
          {/* This section is under construction, come back soon to discover more... */}
        </div>
        {/* {items.length < 3 && (
          <Row>
            {items.map((item, index) => (
              <Col key={index} md="6" className="mb-4">
                <NavItemComponent {...item} className="mb-4 h-100" />
              </Col>
            ))}
          </Row>
        )}
        {items.length === 3 && (
          <Row>
            {items.map((item, index) => (
              <Col key={index} md="4" className="mb-4">
                <NavItemComponent {...item} className="mb-4 h-100" />
              </Col>
            ))}
          </Row>
        )}
        {items.length > 3 && (
          <Row>
            {items.map((item, index) => (
              <Col key={index} md="3" className="mb-4">
                <NavItemComponent {...item} className="mb-4 h-100" />
              </Col>
            ))}
          </Row>
        )} */}
      </div>
    </div>
  );
};

export default Briefing;
