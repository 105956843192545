import React, { Fragment, useMemo } from 'react';
import './hero.css';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getCBreadcrumbItems } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';
import { Link, useLocation } from 'react-router-dom';
import { getIcon } from 'helpers/utils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bg2 from 'assets/img/generic/slide1.jpg';
import { replaceDashesWithSpaces } from 'helpers/utils';
import { replaceSpacesWithDashes } from 'helpers/utils';

const Hero = () => {
  const { t } = useTranslation();
  const { translations } = useSelector(state => state.appsetting);

  const { languageCode, elementContentId, elementContentName } = useSelector(
    state => state.subCategories
  );
  let location = useLocation();
  const pathName = decodeURI(window.location.pathname);

  let hiddenNames = ['Dashboard', 'Portfolio'];
  const countries = ['UK', 'ROI', 'Italy', 'Germany', 'Austria'];
  hiddenNames = [...hiddenNames, ...countries];

  const breadcrumbItems = useMemo(() => {
    let englishBreadcrumbs = getCBreadcrumbItems(
      replaceDashesWithSpaces(pathName),
      hiddenNames
    );
    if (languageCode !== 'GB') {
      englishBreadcrumbs = englishBreadcrumbs.map(breadcrumb => {
        if (breadcrumb.name === elementContentName) {
          return {
            ...breadcrumb,
            name: translations?.[elementContentId]?.[languageCode]?.name || breadcrumb.name,
          };
        }
        return breadcrumb;
      });
    }
  
    return englishBreadcrumbs;
  }, [pathName, elementContentName, languageCode, translations, elementContentId, hiddenNames]);
  
  const sliderSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const category_tree = useSelector(state => state.appsetting.category_tree);

  const dumpSlides = [
    {
      src: bg2
    },
    {
      src: bg2
    }
  ];

  const tempSlides = category_tree.find(c => c.name === 'Carousel Images')
    ? category_tree.find(c => c.name === 'Carousel Images').content_list
    : [];

  const slides =
    tempSlides.length > 0
      ? tempSlides.map(s => {
          return {
            ...s,
            src: s.image01ISfile
          };
        })
      : dumpSlides;

  return (
    <div className="position-relative h-sm-auto overflow-hidden">
      <Slider {...sliderSettings} className="slick-slider-arrow-inner">
        {slides.map((slide, index) => (
          <div className="slide-wrapper" key={index}>
            <Image className="full" src={slide['src']} alt="" key={index} />
          </div>
        ))}
      </Slider>
      <div className="breadcrumbs mt-1 fs-1">
        <Flex
          justifyContent="start"
          alignItems="center"
          className="breadcrumbs-wrapper"
        >
          {location.pathname !== '/' && (
            <div className="breadcrumb">
              <Link to="/" className="px-2 header-link">
                {t('Home')}
              </Link>
            </div>
          )}
          {breadcrumbItems.map((item, index) => (
            <Fragment key={index}>
              {item.show && (
                <>
                  <div className="breadcrumb">
                    {getIcon({
                      icon_component: 'md/MdChevronRight',
                      icon_component_attributes: {
                        fontSize: 20,
                        marginRight: 0
                      }
                    })}
                  </div>
                  <div
                    className={
                      index === breadcrumbItems.length - 1
                        ? 'breadcrumb active'
                        : 'breadcrumb'
                    }
                  >
                    <Link
                      to={replaceSpacesWithDashes(item.url)}
                      className="px-2 text-capitalize header-link"
                    >
                      {t(item.name)}
                    </Link>
                  </div>
                </>
              )}
            </Fragment>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default Hero;
