import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getErrorMesssage } from 'services/axiosHelpers';
import SearchableSelect from 'components/common/SearchableSelect';
import { filterOptionList } from 'helpers/utils';

const RelatedItemAddModal = ({
  show,
  contentId,
  existingOptions,
  handleClose,
  success
}) => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const title = 'Add a related item';
  const btnCaption = 'Save';
  const defaultValue = 'none';

  const initFormData = {
    contentISbb_q4sg_contentID: contentId,
    related_contentISbb_q4sg_contentID: ''
  };

  const [formData, setFormData] = useState(initFormData);

  const handleChange = e => {
    // console.log(e.target.name, e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const makeOptionList = list => {
    const tempList = list.map(o => {
      return {
        ...o
      };
    });
    const result = filterOptionList(
      existingOptions,
      tempList,
      'related_contentISbb_q4sg_contentID'
    );

    return result;
  };

  const getOptionList = async () => {
    try {
      _isMounted.current && setWorking(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content'
      )}?content_related=${contentId}`;
      const res = await axios.get(ep);
      let unlinked = [];
      if (res.data && res.data.list && res.data.list.length > 0) {
        unlinked = makeOptionList(res.data.list);
      }
      const options = unlinked.map(c => {
        return {
          ...c
        };
      });
      // console.log('----options-----', options);
      setOptionList(options);
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setWorking(false);
    }
  };

  const save = async () => {
    try {
      _isMounted.current && setLoading(true);
      const data = formData;

      const ep = `${endpoint.getDataAddEndpoint('bb_q4sg_content_related')}`;
      await axios.post(ep, data);

      // console.log('--------------saved', data, res);
      _isMounted.current && success();
      _isMounted.current && handleClose();
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await save();
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      getOptionList();
      setFormData(initFormData);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size="lg"
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          {/* <Modal.Title as="h5" className="text-white">
            {title}
          </Modal.Title> */}
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <fieldset className={'form-legend-wrapper pb-6 px-4'}>
            <legend className="item-title fs-1 fw-semi-bold">{t(title)}</legend>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 g-3">
                <SearchableSelect
                  loading={working}
                  fieldName="related_contentISbb_q4sg_contentID"
                  label="Select an Item to be linked:"
                  defaultValue={defaultValue}
                  placeholder="List of the selectable items"
                  optionList={optionList}
                  onChangeSelect={handleChange}
                />
              </Row>

              <div className="text-end">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    loading ||
                    !formData.related_contentISbb_q4sg_contentID ||
                    formData.related_contentISbb_q4sg_contentID === defaultValue
                  }
                >
                  {loading ? 'Saving...' : btnCaption}
                </Button>
              </div>
            </Form>
          </fieldset>
        </Modal.Body>
      </Modal>
    </>
  );
};

RelatedItemAddModal.propTypes = {
  show: PropTypes.bool,
  contentId: PropTypes.string,
  existingOptions: PropTypes.array,
  categories: PropTypes.array,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default RelatedItemAddModal;
