import React, { Fragment, useMemo, useEffect, useState, useRef } from 'react';
import PageTitle from 'components/common/PageTitle';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilter } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import Loading from 'components/loading';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { getErrorMesssage } from 'services/axiosHelpers';

const Projects = () => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];

  const projectsData = sub_branches.find(s => s.name === 'Projects') || {};
  const _id = useMemo(() => {
    return projectsData._id;
  }, [projectsData]);

  const title = 'Projects';
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const getInfo = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content'
      )}?category01ISbb_q4sg_content_categoriesID=${_id}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        if (infoData.list && infoData.list.length > 0) {
          _isMounted.current && setProjects(infoData.list);
        }
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (_id) {
      getInfo(_id);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [_id]);

  const onFilter = () => {
    console.log('Clicked Filter');
  };

  const data = useMemo(() => {
    let results = projects.map(p => ({
      _id: p._id,
      name: `${p.name}`,
      date: p.date_start ? moment(p.date_start).format('MM/DD/YY') : '',
      store_plans: p.document01ISfile,
      rendered_visuals: p.document02ISfile,
      meeting_minutes: p.document03ISfile,
      status: p.status,
      owner: p.contact
    }));
    return results;
  }, [projects]);

  const columns = [
    {
      accessor: 'name',
      Header: t('Project Name')
    },
    {
      accessor: 'date',
      Header: t('Date Created')
    },
    {
      accessor: 'store_plans',
      Header: t('Store Plans'),
      Cell: rowData => {
        const { store_plans } = rowData.row.original;
        return (
          <>
            {store_plans && store_plans.uri ? (
              <div className="ms-1 text-center cursor-pointer">
                <a
                  href={`${store_plans.uri}`}
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFile} size="xl" />
                </a>
              </div>
            ) : (
              <div className="ms-1 text-center">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{ opacity: 0.5 }}
                  size="xl"
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      accessor: 'rendered_visuals',
      Header: t('Rendered Visuals'),
      Cell: rowData => {
        const { rendered_visuals } = rowData.row.original;
        return (
          <>
            {rendered_visuals && rendered_visuals.uri ? (
              <div className="ms-1 text-center cursor-pointer">
                <a
                  href={`${rendered_visuals.uri}`}
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFile} size="xl" />
                </a>
              </div>
            ) : (
              <div className="ms-1 text-center">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{ opacity: 0.5 }}
                  size="xl"
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      accessor: 'meeting_minutes',
      Header: t('Meeting Minutes'),
      Cell: rowData => {
        const { meeting_minutes } = rowData.row.original;
        return (
          <>
            {meeting_minutes && meeting_minutes.uri ? (
              <div className="ms-1 text-center cursor-pointer">
                <a
                  href={`${meeting_minutes.uri}`}
                  className=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFile} size="xl" />
                </a>
              </div>
            ) : (
              <div className="ms-1 text-center">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{ opacity: 0.5 }}
                  size="xl"
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: t('Project Status')
    },
    {
      accessor: 'owner',
      Header: t('Project Owner')
    }
  ];

  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <div className="extended-table">
            <AdvanceTableWrapper
              columns={columns}
              data={data}
              sortable
              pagination
              perPage={10}
            >
              <Flex justifyContent="end" alignItems="center" className="mb-2">
                <div className="cursor-pointer" onClick={onFilter}>
                  <FontAwesomeIcon icon={faFilter} size="xl" className="me-2" />
                </div>
                <div>
                  <AdvanceTableSearchBox table />
                </div>
              </Flex>
              <AdvanceTable
                table
                headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
                rowClassName="align-middle white-space-nowrap fs-0"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
              />
              <div className="mt-3">
                <AdvanceTablePagination table />
              </div>
            </AdvanceTableWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
