import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import SplashScreenLoading from 'components/loading/SplashScreenLoading';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setInit, setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { getErrorMesssage } from 'services/axiosHelpers';
import { login } from 'redux/authSlice';

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: true
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handlerAppSettingData();
    onGetUserSession();
  }, []);
  // -------------------------------------------------------------------------------------
  const handlerAppSettingData = async () => {
    try {
      const response = await axios.get(endpoint.settings);
      console.log('-----app_settings----at beginning---------', response.data);
      const data = response.data;

      const background_color = data.background_color;
      const category_tree = data.category_tree;
      const default_route = data.default_route;
      const dropdowns = data.dropdowns;
      const env_files = data.env_files;
      const favicon_16ISfile = data.favicon_16ISfile;
      const favicon_32ISfile = data.favicon_32ISfile;
      const favicon_192ISfile = data.favicon_192ISfile;
      const languages = data.languages;
      const logoISfile = data.logoISfile;
      const name = data.name;
      const regions = data.regions;
      const secondary_color = data.secondary_color;
      const splash_logoISfile = data.splash_logoISfile;
      const title = data.title;
      const translations = data.translations;

      dispatch(
        setInit({
          background_color,
          category_tree,
          default_route,
          dropdowns,
          env_files,
          favicon_16ISfile,
          favicon_32ISfile,
          favicon_192ISfile,
          languages,
          logoISfile,
          name,
          regions,
          secondary_color,
          splash_logoISfile,
          title,
          translations
        })
      );
    } catch (err) {
      let message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onGetUserSession = async () => {
    try {
      /**
       * Check session
       */
      const sessionEp = `${endpoint.session}`;
      const sessionResponse = await axios.get(sessionEp);

      if (sessionResponse?.response?.status === 401) {
        navigate('/login');
        return;
      }

      const user = sessionResponse.data;
      dispatch(
        login({
          isAuthenticated: true,
          user
        })
      );

      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('user', JSON.stringify(user));
    } catch (err) {
      // console.log('session err--------', err);
      if (err && err?.response?.status === 401) {
        navigate('/login');
        return;
      }
    }
  };

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  if (loading) {
    return <SplashScreenLoading />;
  }

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
